#innovations {
    // Usage documentation in imported file
    @import "../../../vendor/150years.min.scss";
    --header-height: 164px;

    h2 {
        color: white;
        text-transform: none;
        font-family: 'Noto Sans';
    }

    .color-dark {
        h2 {
            color: black;
        }
    }
}
