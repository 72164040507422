.text-lottie {
    $self: &;
    position: relative;
    margin-bottom: 40px;

    &--big {
        margin-bottom: 120px;
        @media (min-width: 992px) {
            margin-bottom: 240px;
        }
    }

    
    &--left {
        #{$self}__content {
            flex-direction: column;
            @media (min-width: 767px) {
                flex-direction: row;
            }
        }
    }

    &--right {
        #{$self}__content {
            flex-direction: column;
            @media (min-width: 767px) {
                flex-direction: row-reverse;
            }
        }
    }

    &__headline {
        text-align: center;
        padding-bottom: 12px;

        #{$self}--big & {
            padding-bottom: 1.5rem;
        }

        > * {
            color: var(--text-color, $wilo-dark);
        }

        @media (min-width: 767px) {
            text-align: left;
        }
    }

    &__content {
        align-items: center;
        gap: 48px;
        
        @media (min-width: 767px) {
            gap: 0;
            align-items: flex-start;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__text {
        p {
            font-size: 1rem;
            line-height: 1.625rem;
            color: var(--text-color, $wilo-dark);

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    &__lottie {
        &Caption {
            display: inline-block;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            font-family: $NotoBold;
            font-weight: 700;
        }
    }

    &__cta {
        margin-top: 2rem;
        font-size: 1rem;

        .wilo-btn--outline {
            color: var(--text-color, $wilo-dark);
            border-color: var(--text-color, $wilo-dark);
        }
    }
}