.promo__overlay, .maintenance {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1005;
  width: 100vw;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  overflow:hidden;
  @include transition(transform 0.6s ease-in-out, background-color 0.6s ease-in-out);
}

.promo__overlay--hide {
  @include transform(translateY(-100%));
}

.promo__overlay-inner {
  position: absolute;
  top: 0;
  left: 0;
  width:100vw;
  height:100vh;
  color: #FFFFFF;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promo__overlay-inner--dark {
  @include animation('fadeInBgColor 3s linear forwards');
  @include animation-delay(2s);
}

.promo__overlay-close, .imprint__box-close {
  position: absolute;
  right: 60px;
  top: 30px;
  cursor: pointer;
  @include opacity(0);
  @include animation('fadeIn 2.5s linear forwards');
  @include animation-delay(4s);

  .icon-close {
    color: rgba(255,255,255,1);
    @include transition(color .2s ease-in);

    &:hover {
      color: rgba(255,255,255,.6);
    }
  }

  @media (max-width: 575px) {
    right: 30px;
  }
}

.overlay__video, .maintenance__video {
  width: auto;
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
}

/* Hack for IE11 */
@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, .promo__overlay {
    display: inherit;
  }
}

a.promo__cta.wilo-btn.green:hover {
  background-color: #363640;
}

.promo__textbox {
  text-align: center;
  padding-bottom: 20vh;
  position: relative;
}

.promo__textblock {
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-size: 90px;
  line-height: 110px;
  font-weight: 700;
  @include opacity(0);
  @include animation('bounceText .6s linear forwards');

  &:nth-of-type(1) {
    @include animation-delay(.9s);
  }

  &:nth-of-type(2) {
    @include animation-delay(1.9s);
  }

  &:nth-of-type(3) {
    @include animation-delay(2.9s);
  }

  @media (max-width: 575px) {
    font-size: 80px;
    line-height: 95px;
  }

  .brand-logo-header {
    &:before {
      width:100%;
      margin:0;
    }
  }
}

.cta__box {
  position: relative;
}

.promo__cta {
  @include opacity(0);
  @include animation('fadeIn 1s linear forwards');
  @include animation-delay(5s);
}

.promo__arrows {
  position: absolute;
  top: -80px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  @include opacity(0);
  @include transform(translate(0, -50%));
  @include transition(opacity .3s);
  @include animation('fadeIn 1.5s linear forwards');
  @include animation-delay(4s);
}

.promo-arrow {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  box-sizing: border-box;
  @include transform(rotate(-45deg));
  @include animation('pulseArrow 2s infinite');
  @include opacity(0);

  &:nth-of-type(1) {
    @include animation-delay(0s);
  }

  &:nth-of-type(2) {
    top: 12px;
    @include animation-delay(.15s);
  }

  &:nth-of-type(3) {
    top: 24px;
    @include animation-delay(.3s);
  }
}

// Animate Arrow
@include keyframes(pulseArrow) {
  0% {
    @include opacity(0);
  }
  50% {
    @include opacity(1);
  }
  100% {
    @include opacity(0);
  }
}

// Animate BG-Color
@include keyframes(fadeInBgColor) {
  from   {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,.7);
  }
}

// Animate Text
@include keyframes(bounceText) {
  0% {
    @include opacity(.2);
    @include transform(scale(.2));
  }
  80% {
    @include opacity(0.6);
    @include transform(scale(1));
  }
  100% {
    @include opacity(1);
    @include transform(scale(.9));
  }
}

// Animate Button, Arrows and Close Icon
@include keyframes(fadeIn) {
  from   {
    @include opacity(0);
  }
  to {
    @include opacity(1);
  }
}

/* Maintenance Area */

.maintenance__footer {
  width:100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 1100;
  padding-top:0px;
  background-color: rgba(54, 54, 64, 1);
  max-height: 100vh;
  @include transition(top .5s ease-in-out);

  a {
    color: #FFF;
  }

  .imprint-button {
    -webkit-appearance: none;
    height: 25px;
    font-size: 15px;
    display:inline-block;
    color: #B5B7B8;
    text-decoration: underline;
    cursor:pointer;
  }

  .imprint__box {
    padding: 0 15px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;

    h1, h2, h3, h4, h5, h6 {
      color: #FFFFFF;
    }
  }

  @media (max-width: 480px) {
    .sub-footer .container .social-media, .sub-footer .container p {
      display:none;
    }
  }
  &.maintenance__footer--disabled {
    max-height: none;

    .imprint__box {
      max-height: none;
    }

    .sub-footer .container ul.social-media, .sub-footer .container p {
      visibility:visible;
    }
    .imprint__box-close {
      visibility: hidden;
    }
  }
  .sub-footer .container ul.social-media, .sub-footer .container p {
    visibility: hidden;
  }
  .imprint__box-close {
    visibility: visible;
    top:25px;
  }
}

.maintenance__footer--disabled {
  top: calc(100% - 70px);
}

.maintenance {
  background-color: $wilo-green;
  height: calc(100vh - 70px);
}

@media (max-width: 991px) {
  .maintenance__inner {
    padding-bottom: 70px;
  }
}

.maintenance__textblock {
  font-size: 90px;
}

.maintenance__inner {
  color: #fff;
}

.maintenance__textbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenance__textblock {
  line-height: 1.2;
} 

.maintenance__textblock--sm {
  text-transform: uppercase;
  font-size: 34px;
}

.maintenance__logo:before {
  width: auto;
}

.maintenance__logo {
  line-height: 1.2;
}