.cookie-box {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: rgba(54, 54, 64, 0.95);
    color: #ffffff;
}

.cookie-box-inner {
    padding: 20px 0;
    align-items: center;
    a {
        color: #009c82;
    }
    .wilo-btn {
        background-color: #009c82;
        line-height: normal;
    }
    & > div:first-child {
        line-height: normal;
    }
}