.outline-headline {
    $self: &;
    position: relative;
    margin-bottom: 40px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;



    &__content {
        animation-duration: 600ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;

        #{$self}__headline {
            display: flex;
            flex-direction: row;
            font-size: 40px;

            * {
                font-size: 1em;
                line-height: 1;
                font-weight: 700;
                color: var(--text-color, inherit);
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke: 2px $wilo-green;
                text-align: left;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: normal;
                hyphens: auto;
            }
        }

        #{$self}__topline {
            color: var(--text-color, $wilo-dark);
            font-size: 1rem;
            padding-left: .4em;
        }

        img {
            height: .75em;
            width: auto;
            display: inline;
        }
    }

    &--small {
        padding: 0 140px;
        #{$self}__content {
            #{$self}__headline {
                * {
                    font-size: .75em;
                }      
            }
        }
    }

    &--icon {
        &-left {
            #{$self}__headline {
                flex-direction: row;

                img {
                    margin-right: .4em;
                }
            }
        }
        &-right {
            #{$self}__headline {
                flex-direction: row-reverse;

                img {
                    margin-left: .4em;
                }
            }
        }
    }

    &--align {
        &-center {
            align-items: center;
        }
        &-right {
            align-items: flex-end;
        }
    }

    &--animate {
        &-left {
            --animation-name: fadeInLeft;
        }

        &-right {
            --animation-name: fadeInRight;
        }

        &-flip {
            backface-visibility: visible;
            --animation-name: flipInY;
        }
    }

    &:not(.visible) {
        #{$self}__content {
            opacity: 0;
        }
    }

    &.visible {
        #{$self}__content {
            animation-name: var(--animation-name);
        }
    }

    @media (min-width: 768px) {
        &__content {
            #{$self}__headline {
                font-size: 60px;         
            }
            #{$self}__topline {     
                font-size: 1.5rem;
            }
        }
    }

    @media (min-width: 992px) {
        &__content {
            #{$self}__headline {
                    font-size: 80px;
                // -webkit-text-stroke: 2px $wilo-green;
            }
        }
    }

    @media (min-width: 1200px) {
        &__content {
            #{$self}__headline {

                font-size: 100px;
            }
        }
        
    }

    @media (min-width: 1440px) {
        &__content {
            #{$self}__headline {
                font-size: 120px;
            }
        }
        
    }

    @keyframes fadeInLeft {
        from {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }
        
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }
        
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes flipInY {
        from {
            transform: perspective(400px) rotate3d(0, 1, 0, -75deg);
            opacity: 0;
        }
        
        40% {
            transform: perspective(400px) rotate3d(0, 1, 0, -60deg);           
        }
        
        60% {
            transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            
        }
        
        80% {
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        }
        
        to {
            opacity: 1;
            transform: perspective(400px);
        }
    }
    
}