/* .wilo-dark-blue for Webforms */
.wilo-btn, .wilo-dark-blue {
    padding: 12px;
    cursor: pointer;
    border: none;
    font-size: 13px;
    text-align: left;
    transition: 0.15s background-color ease-in-out;
    display: inline-block;
    color: #fff;
    font-family: $NotoBold;
    &:before {
        font-family: $WiloIcons !important;
        content: "\E903";
        font-size: 12px;
        margin-right: 6px;
    }
}

.wilo-dark-blue {
    background-color: $wilo-dark;
    &:hover {
        background-color: $wilo-green;
    }
}

button {
    border: none;
    background-color: transparent;
}