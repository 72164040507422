.commodities-list-section, .text-block {
    ol {
        width: 66.6%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        li {
            width: 50%;
            margin-bottom: 6px;
            list-style-position: inside;
            list-style-type: decimal-leading-zero;
            font-size: 16px;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            li {
                width: 100%;
            }
        }
    }
}