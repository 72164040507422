.ordered-list {
    ol {
        list-style-position: inside;
        li {
            margin-bottom: 24px;
            .title {
                color: $wilo-dark;
                display: inline-block;
                margin-bottom: 12px;
                text-transform: uppercase;
            }
            &::marker {
                color: $wilo-dark;
            }
        }
        li,
        li .title {
            font-size: 22px;
            font-family: $NotoBold;
        }
    }
}