.default-teaser {
    h2 {
        padding-bottom: 10px;
    }
    
    .teaser-outer {
        .teaser {
            &.green {
                &:hover {
                    .wilo-btn {
                        background-color: $wilo-lightgrey-box;
                        color: $wilo-dark;
                    }
                }
            }
            &:hover {
                .wilo-btn {
                    background-color: $wilo-green;
                }
            }
            .teaser-inner {
                display:block;
                .image-wrapper {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    z-index: -1;
                }
                >* {
                    &:first-child,
                    &.image-wrapper + * {
                        margin-bottom: 48px;
                        @media (max-width: 1199px) and (min-width: 992px), (max-width: 400px) {
                            margin-bottom: 24px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                h4 {
                    font-size: 20px;
                    @media (max-width: 1199px) and (min-width: 992px), (max-width: 575px) {
                        font-size: 18px;
                    }
                }
                .stats {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .stat {
                        text-align: center;
                        p {
                            font-family: $NotoBold;
                            &.desc {
                                font-size: 20px;
                            }
                            &.number {
                                font-size: 54px;
                                @media (max-width: 1199px) and (min-width: 992px), (max-width: 575px) {
                                    font-size: 42px;
                                }
                            }
                        }
                    }
                }
                &.global-contact {
                    p {
                        font-size: 20px;
                        margin-bottom: 24px;
                    }
                    i {
                        color: $wilo-green;
                        padding-right: 12px;
                        font-size: 16px;
                    }
                    ul {
                        list-style-type: none;
                        font-size: 20px;
                    }
                    @media (max-width: 1199px) {
                        p {
                            font-size: 15px;
                        }
                    }
                    @media (max-width: 991px) {
                        height: auto;
                    }
                }

                @media (max-width: 991px) {
                    height: 300px;
                }
                @media (max-width: 575px) {
                    height: 250px;
                    padding: 24px;
                }
                &.download-teaser{
                    @media (max-width: 991px) {
                        height: auto;
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}