.full-size-teaser {
    &.overlay-stage {
        margin-bottom: 96px;
        img {
            margin-bottom: 0;
        }
        .container {
            bottom: -48px;
            .overlay-teaser {
                max-width: 50%;

            }
        }
        .default-stage-media-wrapper {
            margin-bottom: 0;
        }
        @media (max-width: 991px) {
            margin-bottom: 144px;
            .container {
                bottom: -96px;
                .overlay-teaser {
                    max-width: 100%;
                }
            }
        }
        @media (max-width: 767px) {
            margin-bottom: 48px;
        }
    }
    &.home-stage {
        margin-top: -1px;
    }

	&.full-width {
		padding: 0;
		margin-bottom: 30px;
		min-width: 1200px;
		min-height: 300px;
		left:50%;
		transform: translate(-50%, 0);
		width: 101%;

		.default-stage-media-wrapper {
			overflow: hidden;
			margin-bottom:0;

			&__lottie-start {
				margin-bottom:0;
				position: absolute;
				width: 100%;
				height: 100%;

				svg {
					transform: unset !important;
				}
			}
		}

		.default-stage-media {
			margin:auto;
			height: 400%;
		}
	}
}

.stage__container {
	width: 100%;
	overflow: hidden;
}

.default-stage-media-wrapper {
    position: relative;

    &.aspect-ratio-3-15 {
        padding-top: 31.77%
    }

	&.aspect-ratio-45-192 {
		padding-top: 23.43%;
	}
}

.default-stage-media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.stage-headline-wrapper {
    margin-bottom: 4em;
    margin-top: 4em;

    h1 {
        margin-bottom: 0.5em;
    }
}