.navigation {
  z-index: $navigation-z-index;
  position: relative;
  background-color: $wilo-dark;
  a {
    color: $wilo-lightgrey-font;
    &.active {
      color: #fff;
    }
  }
  .meta-navigation {
    & > ul {
      list-style-type: none;
      & > li {
        display: inline-block;
      }
    }
    margin-top: 12px;
    a {
      margin-left: 12px;
      font-size: 13px;
    }
    .languages {
      .current-language {
        font-family: NotoBold, Sans-Serif;
      }
      a {
        &:before {
          content: "|";
          margin-left: -8px;
          margin-right: 7px;
        }
        &:first-child {
          &:before {
            content: "";
            margin-left: 0;
          }
        }
      }
    }
  }
  .main-navigation {
    flex-direction: column;
    .logo-wrapper {
      align-self: flex-start;
      margin-top: 12px;
      .wilo-logo {
        &::before {
          width: auto;
          padding-left: 12px;
        }
      }
    }
    .search-icon-wrapper {
      margin-left: auto;
    }

    .burger {
      margin-left: auto;
      margin-right: 12px;
      z-index: $navigation-burger-z-index;
      opacity: 1;
      padding: 0;
      background-color: transparent;

      &.is-active {
        position: fixed;
        right: 1rem;
        top: 1.5rem;

        @media (max-width: 575px) {
          right: 0.5rem;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
    .menu-items-wrapper {
      .menu-items {
        display: flex;
      }
      & > ul {
        padding: 0;
        list-style-type: none;
        & > li {
          &.active {
            & > a {
              color: #fff;
            }
          }
          & > a {
            display: block;
            padding: 24px 18px;
            font-size: 15px;
            margin-left: 0;
            color: $wilo-lightgrey-font;
            &:first-child {
              padding-left: 12px;
            }
          }
        }
        & > li {
          &.active {
            & > span {
              color: #fff;
            }
            .sub-menu {
              display: block;
            }
          }
          & > span {
            display: block;
            cursor: default;
            padding: 24px 18px;
            font-size: 15px;
            margin-left: 0;
            color: $wilo-lightgrey-font;
            &:first-child {
              padding-left: 12px;
            }
          }
          .sub-menu {
            display: none;
            margin-top: -1px;
            padding: 36px 0px 48px;
            position: absolute;
            top: 164px;
            background-color: $wilo-dark;
            left: 0;
            width: 100%;
            color: #fff;
            .sub-menu-content {
              & > div {
                &:first-child {
                  a {
                    display: block;
                    margin-top: 36px;
                    color: $wilo-yellow;
                    font-family: $NotoBold;
                    &:after {
                      font-family: $WiloIcons !important;
                      content: "\E903";
                      font-size: 10px;
                      margin-left: 6px;
                    }
                  }
                }
              }
              .headline {
                margin-bottom: 24px;
                font-size: 20px;
              }
              ul {
                list-style-type: none;
                li {
                  a {
                    padding: 12px 0;
                    border-top: 1px solid rgba(241, 241, 241, 0.2);
                    display: block;
                    @include transition(all 0.3s ease-in-out);
                    &:hover {
                      color: #fff;
                    }
                  }
                  &:last-child {
                    a {
                      border-bottom: 1px solid rgba(241, 241, 241, 0.2);
                    }
                  }
                }
              }
            }
          }
        }
      }
      @media (max-width: 991px) {
        position: fixed;
        display: none;
        width: 100vw;
        left: 0;
        top: 0;
        flex-direction: column;
        background-color: rgba(54, 54, 54, 0.97);
        justify-content: center;
        .menu-items {
          height: 100vh;
          padding: 24px;
          overflow: auto;
          display: flex;
          flex-direction: column;
          & > li {
            &:first-child {
              margin-top: auto;
            }
            &:last-child {
              margin-bottom: 120px;
            }
            & > span {
              font-size: 4vh;
              padding: 12px;
              text-transform: uppercase;
            }
            .sub-menu {
              background-color: unset;
              position: static;
              padding: 0;
              .container {
                max-width: none;
                .sub-menu-content {
                  & > div {
                    &:first-child {
                      & > *,
                      & > a {
                        display: none;
                      }
                    }
                    ul {
                      li {
                        a {
                          border: none;
                          font-size: 2vh;
                          padding: 6px 0;
                        }
                      }
                    }
                  }
                  img {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        position: fixed;
      }
    }
    @media (min-width: 992px) {
      .menu-items {
        height: 70px;
      }
      .burger {
        display: none;
      }
    }
  }
  .mobile-sub-nav {
    display: none;
    background-color: $wilo-lightgrey-box;
    .container {
      display: flex;
      a {
        color: $wilo-darkgrey;
        padding: 15px 0;
        flex: 1;
        text-align: center;
        p {
          border-right: 1px solid #ccc;
          i {
            margin-right: 2px;
          }
        }
        @media (min-width: 576px) and (max-width: 991px) {
          &:first-child {
            p {
              border-left: 1px solid #ccc;
            }
          }
        }
        @media (max-width: 767px) {
          span {
            display: none;
          }
        }
        @media (max-width: 575px) {
          &:last-child {
            p {
              border-right: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    .meta-navigation {
      display: none;
    }
    .main-navigation {
      height: 75px;
      flex-direction: row;
      align-items: center;
      .wilo-logo {
        display: block;
        margin: 0;
        font-size: 30px;
        &:before {
          margin: 0 12px;
          width: auto;
        }
      }
    }
    .mobile-sub-nav {
      display: block;
    }
  }
}

// Hamburger
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: opacity, filter 0.15s linear;
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }
}

.hamburger-inner {
  &,
  &::before,
  &::after {
    width: 40px;
    position: absolute;
    transition: transform 0.15s ease;
    height: 1px;
    background-color: #fff;
    color: #fff;
    border-radius: 0;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
}

.hamburger--slider {
  .hamburger-inner {
    top: 2px;
    left: 0;
    &::before {
      top: 10px;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }
    &::after {
      top: 20px;
    }
  }
  &.is-active {
    .hamburger-inner {
      transform: translate3d(0, 10px, 0) rotate(45deg);
      &::before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
      }
      &::after {
        transform: translate3d(0, -20px, 0) rotate(-90deg);
      }
    }
  }
}

.mobile-only-element {
  display: none !important;
  @media (max-width: 991px) {
    display: block !important;
  }
}

.nav--open {
  max-height: 100vh;
  overflow: hidden;

  @media (min-width: 992px) {
    overflow: auto;
    max-height: none;
  }
}

.search-icon {
  &::before {
    font-family: $WiloIcons !important;
    content: "\e90f";
    font-style: normal;
  }
}
