.content-slider {
  & .slide {
    &-background-image {
      transition: scale 0.5s ease-in-out;
      background-size: cover;
      height: 300px;

      &:hover {
        scale: 1.2;
      }
    }

    &-headeline-wrapper {
      display: flex;
      position: absolute;
      width: 80%;
      bottom: 30px;
      background-color: $wilo-green;
      opacity: 0.9;
      padding: 15px 15px 15px 37.5px;

      .slide-headline {
        display: flex;
        align-items: flex-start;
        font-size: 1.25rem;
        font-weight: 700;
        color: white;
        text-transform: none;

        &::before {
          font-family: $WiloIcons !important;
          content: "\E903";
          font-size: 12px;
          margin-right: 6px;
          margin-top: 5px;
        }
      }
    }
  }
}