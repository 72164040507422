.teaser-wrapper {
    &--headline {
        h2, p {
            display: block;
            margin: 0 0 24px 24px;
            color: $wilo-dark;
            @media (max-width: 767px) {
                margin-left: 0;
            }
        }
    }
    .teaser-outer {
        margin-bottom: 24px;
        .teaser {
            position: relative;
            padding-top: 90%;
            .image-wrapper {
                overflow: hidden;
                height: 100%;
                img {
                    min-height: 100%;
                    min-width: 100%;
                }
            }
            &.bg-img--zoom {
                .image-wrapper img {
                    transform: scale(1);
                    transition: .5s transform ease-in-out;
                }
                
                &:hover {
                    .image-wrapper img {
                        transform: scale(1.2);
                        transition: .5s transform ease-in-out;
                    }
                }
            }
            .teaser-inner {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                padding: 36px 24px;
                h3,
                h4,
                .stats {
                    font-size: 36px;
                    color: #fff;
                    text-transform: uppercase;
                    &.color-dark {
                        color: $wilo-dark;
                    }
                    @media (max-width: 1199px) and (min-width: 992px) {
                        font-size: 28px;
                    }
                    @media (max-width: 575px) {
                        font-size: 24px;
                    }
                }
            }
            .wilo-btn {
                position: absolute;
                right: 0;
                bottom: 0;

                &.dark {
                    background-color: rgb(54 54 64 / 50%);
                }
            }
        }
        @media (min-width: 992px) {
            &:last-child(),
            &:nth-last-child(2),
            &:nth-last-child(3) {
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 991px) {
        max-width: 720px;
        .teaser-outer {
            &:last-child {
                margin-bottom: 0;
            }
            .teaser {
                padding: 0;
                .teaser-inner {
                    position: static;
                }
            }
        }
    }
}