.table-list-section.table-section {
    table {
        tbody {
            tr {
                border: none;
                td {
                    a {
                        color: $wilo-green;
                        &:before {
                            content: "\E8D2";
                            font-family: $WiloIcons;
                            display: inline-block;
                            padding-right: 13px;
                            color: $wilo-darkgrey;
                        }
                    }
                }
            }
        }
    }
}