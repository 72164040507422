.blog-detail {
    &--category {
        color: $wilo-lightgrey-font;
    }

    &--date {
        color: $wilo-darkgrey;
    }

    &--attributes, 
    &--tags {
        column-gap: .5rem;
        align-items: center;
        flex-wrap: wrap;
    }

    &--tag {
        padding: 0 .5rem;
        border-radius: 5px;
        border: 1px solid $wilo-lightgrey-box;
        color: $wilo-lightgrey-font;
    }
}
