$gutter-width: 7.5px;

/* Margin 0 */

.m--0 {
  margin: 0;
}

.mt--0 {
  margin-top: 0;
}

.mr--0 {
  margin-right: 0;
}

.mb--0 {
  margin-bottom: 0;
}

.ml--0 {
  margin-left: 0;
}

/* Margin 1 */

.m--1 {
  margin: $gutter-width;
}

.mt--1 {
  margin-top: $gutter-width;
}

.mr--1 {
  margin-right: $gutter-width;
}

.mb--1 {
  margin-bottom: $gutter-width;
}

.ml--1 {
  margin-left: $gutter-width;
}

/* Margin 2 */

.mt--2 {
  margin-top: $gutter-width*2;
}

.mr--2 {
  margin-right: $gutter-width*2;
}

.mb--2 {
  margin-bottom: $gutter-width*2;
}

.ml--2 {
  margin-left: $gutter-width*2;
}

/* Margin 4 */

.mt--4 {
  margin-top: $gutter-width*4;
}

.mr--4 {
  margin-right: $gutter-width*4;
}

.mb--4 {
  margin-bottom: $gutter-width*4;
}

.mbl-4 {
  margin-left: $gutter-width*4;
}

@media (min-width: 768px) {
  .mb--md0 {
    margin-bottom: 0;
  }
  .mr--md2 {
    margin-right: $gutter-width*2;
  }
}

@media (min-width: 992px) {}