.accordeon-section {
    width: 66.6%;
    .accordeon-element {
        margin-bottom: 24px;
        @media (max-width: 991px) {
            width: 100%;
        }
        &.active {
            .accordeon-wrapper {
                background-color: $wilo-dark;
                p {
                    span {
                        transform: translateY(-50%);
                    }
                }
            }
            .accordeon-content-wrapper {
                display: block
            }
        }
        .accordeon-wrapper {
            cursor: pointer;
            p {
                font-size: 20px;
                font-family: $NotoBold;
                padding: 12px 54px 12px 18px;
                background-color: $wilo-dark;
                color: #fff;
                text-transform: uppercase;
                position: relative;
                span {
                    transition: transform 0.2s ease-in-out;
                    position: absolute;
                    right: 18px;
                    top: 50%;
                    transform: rotateZ(180deg) translateY(50%);
                }
            }
        }
        .accordeon-content-wrapper {
            display: none;
            padding-top: 24px;
            border-bottom: 1px solid $wilo-dark;
            > * {
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
            .row {
                margin-left: -12px;
                margin-right: -12px;
                width:auto;
            }
            * {
                width: 100%;
            }
            .image-section {
                .row {
                    margin: 0;
                    .col-lg-8 {
                        max-width: 100%;
                        flex: auto;
                        padding: 0;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        width: 100%;
    }
}
