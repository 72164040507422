.image-section {
    width: 66.6%;
    
    &--full {
        width: 100%;
    }   

    p {
        text-align: right;
        font-size: 12px;
        color: $wilo-dark;
        margin-top: 12px;
        @media (max-width: 991px) {
            margin-right: 12px;
        }
    }
    @media (max-width: 1440px) {
        padding: 0;
    }
    @media (max-width: 767px) {
        img {
            padding: 0 0 12px 0;
        }
    }
}