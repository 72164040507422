.iframe-section {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    &.iframe-section--fullscreen {
        .iframe-container {
            position: fixed;
            z-index: 100;
        }

        .iframe-exit-button {
            display: block;
        }
    }
}

.iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.iframe-exit-button {
    display: none;
    position: fixed;
    z-index: 101;
    top: 100px;
    left: 20px;

    @media (max-width: 767px) {
        top: 60px;
    }
}
