.three-column-gallery {
  & .glide {
    &__arrow {
      background: rgba(54, 54, 64, .9);
      border-radius: 100%;
      width: 2.5rem;
      height: 2.5rem;
      color: white;
    }
  }

  & .slide {
    &--image-wrapper {
      position: relative;

      & img {
        width: 100%;
        height: auto;
        max-height: 80vh;
        object-fit: contain;
      }

      & span.slide-count {
        position: absolute;
        left: 50%;
        transform: translateX(50%);
        color: white;
        letter-spacing: 0.2rem;
        bottom: 1rem;
      }

      &:hover {
        img {
          opacity: .3;
          cursor: pointer;
        }
  
        & span.slide-magnify {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
  
          &::before {
            border-radius: 100%;
            content: "\e90f";
            font-family: $WiloIcons !important;
            background-color: #363640;
            color: white;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    &--caption {
      position: absolute;
      bottom: 3rem;
      color: white;
      background-color: $wilo-darkgrey;
      left: 3rem;
      max-width: 40%;
      padding: 1.75rem;

      @media (max-width: 991px) {
        position: unset;
        max-width: 100%;
        padding: 1rem;
      }
    }
  }

  & .modal {
    &--content {
      width: 80%;
      max-width: 1440px;
      margin: auto;
    }

    & .slide {
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
}