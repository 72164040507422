.mwf-form {
    margin-bottom: 36px;
    .wilo-icon {
        display: none;
    }
    .mwf-row {
        margin-bottom: 18px;
    }
    fieldset {
        border: 0;
        width: 100%;
        .col-sm-3 {
            flex: 0 0 100%;
            max-width: 100%;
            label {
                span {
                    display: inline-block;
                }
            }
        }
    }
}

.has-error{
    color: red;
}

.mwf-checkbox,
.mwf-radio {
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  input {
    display: none;
  }
  .mwf-checkbox__text,
  .mwf-radio__text {
    font-size: 16px;
    display: inline-block;
    position: relative;
    margin-left: 30px;
    cursor: pointer;
  }
}

// Forms
// Buttons
.wilo-btn {
    padding: 12px;
    line-height: 16px;
    font-family: $NotoBold;
    position: relative;
    cursor: pointer;
    border: none;
    font-size: 13px;
    text-align: left;
    height: 38px;
    transition: 0.15s background-color ease-in-out;
    display: inline-block;
    margin: 0 5px 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
        margin: 0;
    }

    &:disabled {
        font-style: normal;
    }

    &:before {
        font-family: $WiloIcons;
        content: "\e903";
        margin: 0 5px 0 0;
        font-size: 12px;
        vertical-align: top;
    }

    &.wilo-button-upload,
    &.wilo-btn-primary,
    &.wilo-btn-secondary,
    &.wilo-btn-yellow,
    &.wilo-btn-inverted,
    &.wilo-btn-special,
    &.wilo-btn-shop {
        &[disabled] {
            cursor: default;
            background-color: #dddddd;
            color: #555555;
            font-style: italic;
            font-family: $NotoSans;

            &:hover {
                background-color: #dddddd;
                color: #555555;
            }
        }
    }
    &.wilo-btn-back {
        &:before {
            content: "\e902";
        }
    }
    &.wilo-btn-primary {
        color: #dddddd;
        background-color: #363640;

        &:hover,
        &:focus,
        &:active {
            background-color: #009c82;
        }
    }

    &.wilo-btn--outline {
        border: 1px solid $wilo-dark;
        color: $wilo-dark;
        background-color: transparent;
        line-height: 1;
        height: auto;
        font-size: 16px;
        padding: 12px 24px;
        &:before {
            font-size: 14px;
        }
    }
}

.mwf-hidden {
    display: none;
}

.mwf-button {
    display: inline-block;
}