.history-section {
    --timeline-color: #{$wilo-green};
    --item-gap: 36px;
    position: relative;
    padding-top: var(--item-gap);
    &:before,
    .history-element:after {
            content: ' ';
            display: block;
            position: absolute;
            height: 36px;
            width: 2px;
            background-color: var(--timeline-color);
    }
    &:before {
        top: 0;
    }
    .history-element {
        position: relative;
        display: flex;
        padding-bottom: var(--item-gap);
        img {
            order: 2;
            width: 35%;
            &+.text-wrapper{
                width: 65%;
            }
        }
        .text-wrapper {
            width: 100%;
            padding: 36px;
            background-color: $wilo-lightgrey-box;
            box-sizing: content-box;
            p {
                color: $wilo-dark;
                font-size: 20px;
                &.title {
                    margin-top: 12px;

                }
            }
        }
        &:before {
            left: 0;
            content: ' ';
            position: absolute;
            top: calc(50% - var(--item-gap) / 2);
            translate: -50% -50%;
            display: block;
            width: 24px;
            height: 24px;
            background: #fff;
            border-radius: 50%;
            border: 2px solid var(--timeline-color);
            z-index: 10;
        }
        &:after {
            left: 0;
            height: 100%;
            bottom: 0;
        }
        @media (min-width: 992px) {
            margin-left: auto;
            width: 50%;
            &:nth-child(odd) {
                margin-left: 0;
                img {
                    order: 1;
                }
                .text-wrapper {
                    order: 2;
                }
                &:before {
                    left: 100%;
                    translate: -50% -50%;
                }
                &:after {
                    left: 100%;
                }
            }
        }
        @media (max-width: 575px) {
            img {
                display: none;
            }
            .text-wrapper, img + .text-wrapper { 
                width: 100%;
            }
        }
    }
    &--sponsoring {
        --timeline-color: #{$wilo-lightgrey-font};
        
        &.rowing {
            --highlight-color: #85b9cc;
            --marker-height: 80px;
            --marker-width: 40px;
            
        }
        &.football {
            --highlight-color: #fae104;
            --marker-height: 40px;
            --marker-width: 40px;
        }
        


        .history-element {
            display: flex;
            flex-direction: column-reverse;

            @media (min-width: 992px) {
                &:nth-child(odd) {                  
                    &:before {
                        left: 100%;
                    }

                    img,
                    .text-wrapper {
                        translate: -100% 0;
                    }

                    .year {
                        text-align: end;
                    }
                }
            }

            img,
            .text-wrapper {
                display: block;
                translate: 100% 0;
                opacity: 0;
                transition: 
                    translate  600ms ease-in-out, 
                    opacity 600ms ease-in-out;
            }

            &::before {
                top: calc(36px + 2.5rem * 1.5);
                left: 0;
                translate: -50% -50%;
                // background: var(--asset-path); // TODO wait for asset
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                opacity: 0;
                height: var(--marker-height);
                width: var(--marker-width);
                border: none;
                transition: opacity 600ms ease-in-out;
            }

            &.visible {
                &::before {
                    // opacity: 1; // TODO wait for asset
                }

                img,
                .text-wrapper {
                    opacity: 1;
                    translate: 0 0;
                }
            }

            img {
                width: 100%;
                padding-inline: 36px;
            }
            .text-wrapper, img + .text-wrapper { 
                width: unset;
                max-width: 100%;
            }

            .text-wrapper {
                background-color: unset;
                .year {
                    font-size: 5rem;
                    font-family: $NotoBold;
                    color: var(--highlight-color);
                }
                .title {                   
                    font-size: 1.5rem;
                    font-family: $NotoBold;
                }
                .text {
                    margin-top: 15px;
                    
                    p {
                        margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }

        }
    }
    @media (min-width: 992px) {
        &:before {
            left: 50%;
        }
    }
}