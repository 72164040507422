.annual-report-map {
    $self: &;
    margin-bottom: 48px;

    &__headline {
        padding-bottom: 12px;
        max-width: 700px;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
    }

    &__text {
        padding-bottom: 40px;
        max-width: 700px;
        font-size: 18px;
        line-height: 22px;
    }

    &__map-container {
        height: 500px;
        position: relative;

        .jvm-zoom-btn {
            padding: 12px;
            background-color: rgba(241, 245, 247, 0.20);
            border: 1px solid #B9BDBF;
            color: #000;
            line-height: 24px;
            font-size: 24px;
            text-align: center;
            vertical-align: middle;
            height: unset;
            width: 48px;

            &.jvm-zoomout {
                top: unset;
                bottom: 10px;
            }
            &.jvm-zoomin {
                top: unset;
                bottom: 58px;
            }
        }
    }

    &__dataTable {
        padding-top: 40px;
        font-size: 14px;

        &#{$self}--single-column {
            .dataTable__row {
                grid-template-columns: 100%;
            }
        }
       
        .dataTable__row {
            display: grid;
            grid-template-columns: 45% 25% 25%;
            justify-content: space-between;
            padding-bottom: 6px;
            padding-right: 4px;
            border-bottom: 1px solid #DCDCDC;

            &, 
            * {
                line-height: normal;
            }

            &--head {
                padding-bottom: 20px;
            }

            > div {
                &:first-child {
                text-wrap: wrap;
                word-wrap: break-word;
                hyphens: auto;
                }

                &:not(:first-child) {
                    text-align: end;
                }

                a {
                    color: $wilo-green;
                }
            }
        }
    }

    @media (min-width: 768px) {
        &__dataTable {
            display: none;
        }
    }
}

.jvm-tooltip {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
}

.jvm-tooltip--fake {
    height: auto;
    width: auto;
    visibility: visible;
    border-radius: 0;
    padding: 24px 24px 24px 40px;
    background-color: white;
    color: black;
    .annual-report-map__popup {
        line-height: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
    
        .annual-report-map__country {
            font-size: 1rem;
            line-height: 1.5rem;
            
            .wilo-icon {
                font-size: 24px;
                color: #F65751;
                position: absolute;
                padding-right: 8px;
                translate: -100% 0%;
            }
        }
    
        .annual-report-map__populations {
            line-height: 1;
            display: flex;
            flex-direction: row;
            gap: 24px;
    
            * {
                line-height: 1;
            }
    
            .populations__col {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 14px;
                white-space: nowrap;
            }
        }
    
        .annual-report-map__cta {        
            color: $wilo-green;
        }
    }
}