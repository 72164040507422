.background_full_width_image {
  color: white;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 3rem;
  
  .wilo-btn {
    &.dark {
      background-color: $wilo-dark;
    }
  
    &.green {
      background-color: $wilo-green;
  
      &:hover {
        background-color: $wilo-dark;
      }
    }
  
    &:hover {
      background-color: $wilo-green;
    }
  }

  .image-wrapper {
    overflow: hidden;
    position: relative;
  }

  .image-description {
    color: $wilo-dark;
    text-align: end;
  }

  a {
    color: white;
  }

  img {
    -webkit-transition: translate 10ms linear;
    -o-transition: translate 10ms linear;
    transition: translate 10ms linear;
  }

  .text-wrapper {
    color: white;
    z-index: 1;
    width: 30% !important;
    top: 22%;
    margin-top: 3rem;
    padding: 30px;
    position: absolute;

    @media (max-width: 1279px) {
      width: 100% !important;
      position: unset;
      top: unset;
      opacity: unset;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 16px;
      font-weight: 700;
      color: white;

      @media (min-width: 1280px) {
        font-size: 36px;
      }
    }

    &.dark {
      background-color: $wilo-dark;
      opacity: 0.95;
    }

    
  }

  @media (max-width: 1279px) {

    //relevant for white background - other background-colors will overwrite this
    .text-wrapper {
      margin-top: 0;
      color: $wilo-dark;
      h2 {
        color: $wilo-dark;
      }
      &.dark {
        background-color: unset !important;
        a {
          color: white;
        }
      }

      &.green {
        background-color: unset !important;
      }
    }
  }
}

