@import "./st_keyFiguresTeaser.scss";
@import "./st_defaultTeaser.scss";
@import "./st_contactTeaser.scss";
@import "./st_downloadTeaser.scss";

.four-column-teaser {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1rem;

  //TODO: IE11 highlight
  @mixin template-areas-xl {
    &.equal-sized-grid {
      grid-template-columns: repeat(4, 1fr);
    }
    grid-template-areas: ". . . ." ". . . ." ". . . ." ". . . .";
    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
    -ms-grid-rows: 350px 10px 350px 10px 350px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    .teaser-3 {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }

    .teaser-4 {
      -ms-grid-row: 1;
      -ms-grid-column: 7;
    }

    .teaser-5 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-6 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    .teaser-7 {
      -ms-grid-row: 3;
      -ms-grid-column: 5;
    }

    .teaser-8 {
      -ms-grid-row: 3;
      -ms-grid-column: 7;
    }

    .teaser-9 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-10 {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }

    .teaser-11 {
      -ms-grid-row: 5;
      -ms-grid-column: 5;
    }

    .teaser-12 {
      -ms-grid-row: 5;
      -ms-grid-column: 7;
    }
    /** END IE11 base-layout **/
  }

  @mixin template-areas-large {
    &.equal-sized-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-template-areas: ". . ." ". . ." ". . ." ". . .";
    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
    -ms-grid-rows: 300px 10px 300px 10px 300px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    .teaser-3 {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }

    .teaser-4 {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    .teaser-5 {
      -ms-grid-row: 2;
      -ms-grid-column: 3;
    }

    .teaser-6 {
      -ms-grid-row: 2;
      -ms-grid-column: 5;
    }

    .teaser-7 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    .teaser-9 {
      -ms-grid-row: 3;
      -ms-grid-column: 5;
    }

    .teaser-10 {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
    }

    .teaser-11 {
      -ms-grid-row: 4;
      -ms-grid-column: 3;
    }

    .teaser-12 {
      -ms-grid-row: 4;
      -ms-grid-column: 5;
    }
    /** End IE11 base-layout **/
  }

  @mixin template-areas-medium {
    &.equal-sized-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-template-areas:
      ". . "
      ". . "
      ". . "
      ". . ";

    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr 10px 1fr;
    -ms-grid-rows: 300px 10px 300px 10px 300px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    .teaser-3 {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    .teaser-4 {
      -ms-grid-row: 2;
      -ms-grid-column: 3;
    }

    .teaser-5 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-6 {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    .teaser-7 {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 4;
      -ms-grid-column: 3;
    }

    .teaser-9 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-10 {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }

    .teaser-11 {
      -ms-grid-row: 6;
      -ms-grid-column: 1;
    }

    .teaser-12 {
      -ms-grid-row: 6;
      -ms-grid-column: 3;
    }

    /** End IE11 base-layout **/
  }

  @mixin template-areas-small {
    &.equal-sized-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-template-areas:
      "."
      "."
      "."
      ".";

    /** Start IE11 base-layout **/
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 250px 10px 250px 10px 250px;

    .teaser-1 {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .teaser-2 {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    .teaser-3 {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .teaser-4 {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
    }

    .teaser-5 {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
    }

    .teaser-6 {
      -ms-grid-row: 6;
      -ms-grid-column: 1;
    }

    .teaser-7 {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
    }

    .teaser-8 {
      -ms-grid-row: 8;
      -ms-grid-column: 1;
    }

    .teaser-9 {
      -ms-grid-row: 9;
      -ms-grid-column: 1;
    }

    .teaser-10 {
      -ms-grid-row: 10;
      -ms-grid-column: 1;
    }

    .teaser-11 {
      -ms-grid-row: 11;
      -ms-grid-column: 1;
    }

    .teaser-12 {
      -ms-grid-row: 12;
      -ms-grid-column: 1;
    }

    /** End IE11 base-layout **/
  }

  @include template-areas-small;

  @media (min-width: 690px) {
    @include template-areas-medium;
  }

  @media (min-width: 1024px) {
    @include template-areas-large;
  }

  @media (min-width: 1280px) {
    @include template-areas-xl;

    &.top-left {
      grid-template-areas:
        "a a . ."
        "a a . ."
        ". . . .";
    }

    &.top-center {
      grid-template-areas:
        ". a a ."
        ". a a ."
        ". . . .";
    }

    &.top-right {
      grid-template-areas:
        ". . a a"
        ". . a a"
        ". . . .";
    }
    &.bottom-left {
      grid-template-areas:
        ". . . ."
        "a a . ."
        "a a . .";
    }

    &.bottom-center {
      grid-template-areas:
        ". . . ."
        ". a a ."
        ". a a .";
    }

    &.bottom-right {
      grid-template-areas:
        ". . . ."
        ". . a a"
        ". . a a";
    }

    .highlight {
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-area: a;
    }
  }
  & > div {
    @media (min-width: 500px) and (max-width: 689px) {
      padding-top: 60%;
    }
  }
}
