.blog-teaser {
  padding-top: 5px;
  
  @media (min-width: 1280px) {
    .highlight {
      .tile--headline {
        font-size: 1.75rem;
      }

      .tile--intro {
        display: inherit;
        margin-top: 1.25rem;
      }
    }
  }

  & > div {
    @media (min-width: 500px) and (max-width: 689px) {
      padding-top: 0;
    }
  }
}
