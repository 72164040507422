@import "~@wilo/common/css/fontawesome/all.css";
@import "~@wilo/common/css/fonts.css";
@import "~@wilo/common/css/icons.css";
$NotoSans: "Noto Sans", sans-serif;
$NotoBold: "NotoBold", sans-serif;
$NotoBoldItalic: "NotoBoldItalic", sans-serif;
$NotoItalic: "NotoItalic", sans-serif;

$WiloIcons: "wilo-icons", sans-serif;

// Colors
$wilo-green: #009C82;
$wilo-dark: #363640;
$wilo-grey: #5E5E67;
$wilo-lightgrey-font: #B5B7B8;
$wilo-darkgrey: #4A4A4A;
$wilo-lightgrey-box: #E9EAEA;
$wilo-yellow: #FFB400;
$wilo-blue:#022E4A;

// Bootstrap
$grid-gutter-width: 24px;

//z-index, missing alot
$modal-z-index: 105;
$modal-content-z-index: 106;

$navigation-z-index: 100;
$navigation-burger-z-index: 1001;