.news-overview-stage.full-size-teaser.default-stage {
    margin-bottom: 72px;
    .container {
        .overlay-teaser {
            max-width: none;
            height: 185px;
            width: 205px;
            margin-bottom: 0;
            padding: 0 6px;
            background-color: unset;
            position: relative;
            &:first-child {
                padding-left: 12px;
            }
            &:last-child {
                padding-right: 12px;
            }
            .overlay-teaser-content {
                width: 100%;
                height: 100%;
                cursor: pointer;
                @include transition(all .1s ease-in-out);
                &.active, &:hover, &:active, &:focus {
                    background-color: #fff;
                    .overlay-teaser-content-inner {
                        p,
                        i {
                            color: $wilo-green;
                        }
                    }
                }
                .overlay-teaser-content-inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    p {
                        font-size: 20px;
                        color: #fff;
                        text-transform: uppercase;
                        font-family: $NotoBold;
                    }
                    i {
                        color: #fff;
                        background: transparent;
                        font-size: 32px;
                        margin-bottom: 24px;
                    }
                    @media (max-width: 1199px) {
                        p {
                            font-size: 15px;
                        }
                        i {
                            font-size: 28px;
                        }
                    }
                    @media (max-width: 991px) {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        top: 0;
                        left: 0;
                    }
                    @media (max-width: 767px) {
                        p {
                            font-size: 12px;
                        }
                        i {
                            font-size: 22px;
                        }
                    }
                    @media (max-width: 575px) {
                        p {
                            display: none;
                        }
                        i {
                            margin: 0;
                            font-size: 20px;
                        }
                    }
                }
            }
            @media (max-width: 1199px) {
                width: 180px;
                height: 160px;
            }
        }
    }
    @media (max-width: 991px) {
        margin-bottom: 24px;
        .container {
            position: static;
            max-width: 100%;
            transform: none;
            padding: 0;
            .row {
                margin: 0;
                flex-wrap: nowrap;
                .overlay-teaser {
                    height: auto;
                    padding: 0;
                    width: 100%;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-left: 0;
                    }
                    .overlay-teaser-content {
                        position: relative;
                        padding-top: 100%;
                    }
                }
            }
        }
    }
}