.award-slider {
    background-color: $wilo-lightgrey-box;
    .award-slider-inner {
        padding: 24px 36px;
        height: 110px;
        .slider {
            display: flex;
            flex-wrap: nowrap;
            .image-wrapper {
                display: flex;
                justify-content: center;
                background-color: $wilo-lightgrey-box;
                img {
                    max-height: 100%;
                    width: auto;
                    padding: 0 84px;
                    mix-blend-mode: multiply;
                    filter: saturate(0);
                    @include transition(all .3s ease-in-out);
                    opacity: 0.7;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    &:hover {
                        opacity: 1;
                        filter: saturate(1);
                    }
                }
            }
        }
    }
    @media (max-width: 1440px) {
        padding: 0;
    }
    @media (max-width: 767px) {
        display: none;
    }
}