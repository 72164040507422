.glide {
    &__slides {
        align-items: center;
        justify-content: center;
    }

    &__track {
        margin-left: 12px;
        margin-right: 24px; //added half of glidejs gap, only px possible
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.2rem;
        height: 1.2rem;
        cursor: pointer;
        
        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }

    &__slide--uninitialized {
        width: 33%;
        margin-right: 0.8rem;
    }

    & .slide {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

@media (max-width: 1000px) {
    .glide__slides--uninitialized {
        flex-wrap: wrap;
    }

    .glide__slide--uninitialized {
        width: 45%;
        margin-bottom: 1rem;
    }
}

@media (max-width: 600px) {
    .glide__slides--uninitialized {
        display: block;
    }

    .glide__slide--uninitialized {
        width: 100%;
        margin-bottom: 1rem;
    }
}