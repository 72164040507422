.rt_stage_slider {
    margin-bottom: 48px;
    touch-action: pan-y;

    &--item {
        position: relative;
    }

    &--content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: -o-linear-gradient(bottom, #000 0%, transparent 100%);
        background: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
        background: linear-gradient(0deg, #000 0%, transparent 100%);
        padding: 7.5rem 0.75rem 2.5rem 0.75rem;
        text-align: center;

        h2,
        p {
            color: #fff;
        }
    }

    &--img {
        overflow: hidden;
        height: 100%;

        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .tns-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 3rem;

        button {
            background: #e9eaea;
            width: 50px;
            height: 5px;
            margin: 3px;
            border-radius: unset;
            cursor: pointer;

            &.tns-nav-active {
                background: #363640;
            }
        }
    }
}

.tns-slide-active .rt_stage_slider--img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include animation(zoom 12s ease-in-out 1);

    @include keyframes(zoom) {
        0% {
            @include transform(scale3d(1, 1, 1));
        }

        100% {
            @include transform(scale3d(1.1, 1.1, 1.1));
        }
    }
}

@media (orientation: portrait) {
    .rt_stage_slider {

        &__inner,
        &--item {
            height: 75vH;
        }
    }
}

@media (orientation: landscape) {
    .rt_stage_slider {

        &__inner,
        &--item {
            height: 65vH;
        }
    }
}