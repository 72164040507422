.contact-teaser {
    .teaser-outer {
        .teaser {
            .teaser-inner {
                h3 {
                    color: $wilo-dark;
                    font-size: 20px;
                    margin-bottom: 12px !important;
                }
                .contact-name { 
                    text-transform: uppercase;
                }
                div {
                    p {
                        font-size: 20px;
                        &:last-child {
                            margin-bottom: 12px;
                        }
                    }
                    &:last-child {
                      p {
                          &:last-child {
                              margin-bottom: 0;
                          }
                      }  
                    }
                }
                i {
                    color: $wilo-green;
                    padding-right: 12px;
                }

                .contact-adress {
                    margin-top:15px;
                }

                @media (max-width: 1199px) {
                    div p, h3{
                        font-size: 15px;
                    }
                }
                @media (max-width: 767px) {
                    height: auto;
                }
            }
        }
    }
}