input[type="text"], textarea {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 15px;
    color: $wilo-darkgrey;
    border: 1px solid $wilo-lightgrey-font;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &:focus {
        border: 1px solid #009c82;
        box-shadow: none;
    }
}

input[type="text"] {
    height: 38px;
}

::-webkit-input-placeholder {
    font-family: $NotoSans;
    color: $wilo-lightgrey-box;
    font-size: 15px;
}

:-moz-placeholder {
    font-family: $NotoSans;
    color: $wilo-lightgrey-box;
    font-size: 15px;
}