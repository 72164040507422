.back-to-overview {
    .wilo-btn {
        width: 100%;
        display: inline-block;
    }
}

.lm-section {
    text-align: center;
    .wilo-btn {
        &:hover, &:active, &:focus, &:visited {
            background-color: #009C82;
        }
    }
}

.news-detail {
    &--category {
        color: $wilo-lightgrey-font;
    }

    &--date {
        color: $wilo-darkgrey;
    }
}
