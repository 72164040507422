.text-on-image {
    $self: &;
    position: relative;
    perspective: 100px;
    perspective-origin: bottom center;
    margin-bottom: 120px;

    &__image {
        transform-origin: bottom center;
        scale: var(--progress, 0);
        width: 100%;
        overflow: hidden;
        aspect-ratio: 7 / 9;

        picture {
            display: block;
        }
    }

    &__textWrapper {
        position: relative;
        margin-top: -144px;
    }

    &__text {
        position: relative;       
        background-color: white;
        transition: translate 600ms ease-out, opacity 600ms ease-out;
        padding: 65px 24px 40px 69px;
        margin: 0 12px;
        opacity: 0;
        translate: 0 100%;

        &,
        * {
            font-size: 1rem;
            line-height: 1.625rem;
        }
    }

    &__icon {
        position: absolute;
        top: 24px;
        left: 24px;
        content: '';
        display: block;
        aspect-ratio: 1 / 1;
        width: 50px;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &.visible {
        #{$self}__text {
            opacity: 1;
            translate: 0 0;
        }
    }

    @media (min-width: 768px) {
        &__image {
            width: 100%;
            aspect-ratio: 16 / 9;
            
            img {
                width: 100%;
                translate: 0 0;
            }
        }

        &__textWrapper {
            position: absolute;
            inset: 0;
            margin-top: 0;
        }

        &__text {
            position: absolute;
            bottom: 10%;
            width: 352px;
            translate: 0 50%;
            padding: 65px 40px 40px 69px;
        }

        &--right {
            #{$self}__text {
                right: 0;
            }
        }

        &--left {
            #{$self}__text {
               left: 0;
            }
        }
    }

    @media (min-width: 992px) {
        &__image {
            aspect-ratio: 21 / 9;
        }

        &__text {
            bottom: 20%;
            width: 457px;
        }
    }
}