.sidenav {
    position: fixed;
    right:0;
    top:25%;
    z-index:$navigation-z-index + 1;
    @media all and (max-width: 991px) {
        display:none;
    }

    .countries-box, .contact-box {
        position: relative;
        background-color: $wilo-green;
        width: 70px;
        height: 60px;
        margin-bottom: 12px;
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index:65;
        @include transition(background-color .15s ease-in-out);

        &:hover {
            background-color: $wilo-dark;
        }

        &:last-child {
            margin-bottom: 0;
        }
        span {
            font-size: 10px;
        }
        i {
            font-size: 18px;
            margin: 6px 0;
        }
    }
    .info-box {
        position: absolute;
        top: 0;
        right: 70px;
        background-color: $wilo-lightgrey-box;
        color: $wilo-darkgrey;
        width: auto;
        padding: 19px 19px;
        white-space: nowrap;
        z-index:60;
        max-height: 60px;

        @include transition(all .3s ease-in-out);
        &.hide {
            transform: translateX(101%);
            @include transition(all .3s ease-in-out);
        }
    }
}