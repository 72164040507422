.annual-report-stage {
    padding-block: 70px 60px;

    &--hidden {
        height: 0;
        visibility: hidden;
        padding: 0;
    }

    &__headline {
        color: #ffffff;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 400;
        text-transform: uppercase;
        padding-bottom: 8px;
    }

    .outline-headline {
        margin-bottom: 0;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &__content {
            .outline-headline__headline {
                * {
                    line-height: 1;
                    font-size: 50px;
                } 
            }
        }
    }

    @media (min-width: 768px) {
        .outline-headline {
            &__content {
                .outline-headline__headline { 
                    * {
                        font-size: 90px;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        padding-block: 280px 120px;

        &--hidden {
            height: 0;
            visibility: hidden;
            padding: 0;
        }
        
        &__headline {
            font-size: 22px;
            padding-bottom: 0;
        }

        
        .outline-headline {
            margin-bottom: 0;
            
            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &__content {
                .outline-headline__headline {
                    * {
                        font-size: 120px;
                    }
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .outline-headline {
            &__content {
                .outline-headline__headline {
                    * {
                        font-size: 150px;
                    }
                }
            }
        }
    }
}