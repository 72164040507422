.image-text-section {
    .row {
        .col-custom {
            flex: 0 0 auto;
            min-width: 50%;
            padding-left: 12px;
            padding-right: 12px;
        }

        .col-custom:not(:only-child) {
            flex: 1 0 auto;
        }

        @media (max-width: 991px) {
            .col-custom {
                flex: 1 0 auto;
            }
        }

        p,
        img,
        ul {
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: 768px) {
            .left {
                order: 1;
            }

            .right {
                order: 2;
            }
        }
    }

    img {
        align-self: flex-start;
        max-width: 50%;

        @media (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 24px;
        }

        &.content-img {
            max-width: 100%;
        }
    }

    p &.caption {
        text-align: right;
        font-size: 12px;
        color: $wilo-dark;
        margin-top: 12px;
    }

    .image-text-inner {
        max-width: 50%;

        @media (max-width: 767px) {
            max-width: 100%;
            padding-bottom: 12px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}