.text {
    h2,
    h3 {
        color: $wilo-dark;
        margin-bottom: 12px;
    }
    h3 {
        font-size: 20px;
    }
    p {
        margin-bottom: 24px;
    }
    .row {
        .col-custom {
            flex: 0 0 auto;
            min-width: 50%;
            padding-left: 12px;
            padding-right: 12px;
        }

        .col-custom:not(:only-child) {
            flex: 1 0 auto;
        }

        @media (max-width: 991px) {
            .col-custom {
                flex: 1 0 auto;
            }
        }
    }
    .buttons-wrapper {
        .row {
            .col-custom {
                flex: 0 0 auto;
                min-width: 50%;
                max-width: 100%;
                padding-left: 12px;
                padding-right: 12px;
            }
    
            .col-custom:not(:only-child) {
                flex: 1 0 auto;
            }
    
            @media (max-width: 991px) {
                .col-custom {
                    flex: 1 0 auto;
                }
            }
            .wilo-btn {
                width: 100%;
                display: inline-block;
                margin-bottom: 24px;
                white-space: normal;
                height: fit-content;
            }
            div {
                &:last-child() {
                    .wilo-btn {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}