select {
  width: 100%;
  color: #FFFFFF;
  padding: 7px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  font: normal 15px $NotoSans;
  background: $wilo-grey url('../../media/forms/select/dropdown.png') no-repeat;
  background-position:  right 0px center;
  transition: 0.15s background-color ease-in-out;

  &:after {
    content: "\E901";
    float: right;
    font-weight: 100;
    font-size: 12px;
    margin-right: 6px;
    font-family: $WiloIcons !important;
    display: block;
    position: relative;
  }
}

select::-ms-expand {
  display: none;
}

select.desktopDropDown::-ms-expand {
  display: none;
}