.download-list{
    list-style: none;
    margin-left: 12px;

    li{
        position: relative;
        padding: 6px 0 0 12px;

        &:before{
            content: "\E8D2";
            font-family: $WiloIcons; 
            position: absolute;
            display: inline-block; 
            left: -12px; 
            /* width: 12px;  */
        }

        &:first-child {
            padding-top:12px;
        }
    }


    a, a:hover{
        color: $wilo-green !important;
        font-size: 15px;
    }

    i {
        font-size: 22px;
        padding-right: 12px;
    }
}