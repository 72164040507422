.wilo-ww {
    .wilo-custom-select {
        width: 100%;
        background: $wilo-grey;
        color: #FFF;
        margin-bottom: 0px;

        &:before {
            content: "\e901";
            float:right;
            font-size: 14px;
            font-family: $NotoSans;
            font-weight: 100;
        }
    }

    .dropdown {
        margin-top:24px;
        position: relative;
        &.active {
            .wilo-custom-select {
                background-color: $wilo-green;
            }
            .wilo-custom-dropdown-menu {
                display:block;
            }
        }

        .wilo-custom-dropdown-menu {
            width: 100%;
            border: 1px solid #03998A;
            margin-top: 0px;
            height: auto;
            max-height: 200px;
            overflow-x: hidden;
            padding: 0;
            border-radius: 0;
            position: absolute;
            z-index: 99;
            display:none;
            > li {
                border-bottom: 1px #F1F1F1 solid;
                vertical-align: middle;
                padding: 10px;
                transition: background-color 0.3s ease-in-out;
                background-color: #FFFFFF;
                > a {
                    &:hover, &:focus {
                        background-color: #F1F1F1;
                    }
                }
                > div {
                    margin-bottom: 3px;
                    margin-top: 3px;
                    > label > p {
                        margin-left: 10px;
                    }
                }
                &:hover, &:visited, &:focus, &:active {
                    background-color: #F1F1F1;
                }
            }
        }
    }

    .accordeon-section {
        margin-top:48px;
        margin-bottom: 0px;

        .accordeon-element {
            .www-meta {
                text-transform: uppercase;
                color: $wilo-grey;
                padding:0 0 12px;
                font-size:14px;
                .continent {
                    &:after {
                        padding:0 12px;
                        content: ">";
                    }
                }
            }
            .www-contact {
                padding-bottom:24px;
                line-height:1.8;
                p {
                    margin-bottom:12px;
                    line-height:1.8;
                    &:last-child {
                        margin-bottom:0px;
                    }
                }
                ul {
                    list-style-type: none;
                    li {
                        padding:3px 0;
                        i {
                            color: $wilo-green;
                        }
                    }
                }
                .wilo-btn {
                    margin-top:12px;
                }
            }
        }
    }

}