.teaser__default-teaser {
  position: relative;
  padding-top: 90%;

  &.lightgrey {
    background-color: #e9eaea;
  }

  &.green {
    background-color: #009c82;
  }

  &.dark {
    background-color: #363640;
  }

  .teaser-inner {
    display: block;
    background-size: cover;
    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    h3 {
      font-size: 1.5rem;
      font-family: $NotoBold;
      text-transform: uppercase;
      color: white !important;
      &.color-dark {
        color: #363640 !important;
      }
      margin-bottom: 2rem;
    }

    h4 {
      font-size: 1rem;
      font-family: $NotoBold;
      text-transform: uppercase;
      color: white;
      &.color-dark {
        color: #363640;
      }
    }

    .wilo-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      &.dark {
        background-color: #363640;
      }
    }
  }

  &:hover {
    .teaser-inner {
      .wilo-btn {
        background-color: #009c82;
      }
    }
  }
}
