.key-visual {
    position: relative;
    border-bottom: 10px solid $wilo-green;
    margin-bottom: 120px;

    &:not(.visible) {
        .key-visual__text,
        .key-visual__headline {
            translate: -50% 0;
            opacity: 0;
        }
    }

    &.visible {
        .key-visual__text,
        .key-visual__headline {
            translate: 0 0;
            opacity: 1;
        }
    }

    &__headline {
        transition: translate 0.5s ease-out, opacity 0.5s ease-out;
        color: $wilo-green;
        padding-bottom: 40px;
        p {
            font-size: 1.875rem;
            line-height: 1;
            font-weight: 700;
            letter-spacing: 4px;
        }

    }

    &__text {
        transition: translate 0.5s ease-out, opacity 0.5s ease-out;
        transition-delay: 0.3s;
        p {
            font-size: 1.125rem;
            line-height: 1.5rem;
            max-width: 241px;
            font-weight: 400;
        }
    }

    &__image {
        picture {
            position: relative;
            display: block;
            overflow: hidden;
            aspect-ratio: 7/9;

            img {
                height: auto;
            }
        }
    }

    &__textWrapper {
        position: absolute;
        inset: 0;
        margin-top: 40px;
        color: #ffffff;
        text-transform: uppercase;
    }



    @media (min-width: 768px) {

        &__image {
            picture {
                aspect-ratio: 16/9;
                img {                    
                    width: 100%;
                    height: auto;
                    left: unset;
                    translate: unset;
                }
            }
        }
        &__textWrapper {           
            margin-top: 10%;
        }

        &__headline,
        &__text {
            max-width: 524px;

        }   
    }

    @media (min-width: 992px) {
        margin-bottom: 240px;
        &__headline {
            max-width: 624px;

            p {
                font-size: 5rem;
                line-height: 1;
            }
        }
        &__text {
            max-width: 624px;

            p {
                font-size: 1.375rem;
                line-height: 1.875rem;
                max-width: 458px;
            }
        }
        &__image {
            picture {
                aspect-ratio: 21/9;
            }
        }
    }

}