.footer {
    padding-top: 48px;
    @include background-opacity($wilo-dark, 0.9);
    color: $wilo-lightgrey-font;
    > .container {
        margin-bottom: 48px;
        .wilo-logo {
            +p {
                font-size: 14px;
                font-family: $NotoSans;
                margin-top: 24px;
                text-transform: none;
                color: #fff;
                @media (max-width: 991px) {
                    display: none;
                }
            }
        }
        .icon-arrow-top {
            color: #fff;
            font-size: 24px;
        }
        p {
            font-size: 18px;
            margin-bottom: 24px;
        }
        ul {
            list-style-type: none;
            li {
                font-size: 15px;
                margin-bottom: 12px;
                a {
                    color: $wilo-lightgrey-font;
                    cursor: pointer;
                    i {
                        margin-right: 3px;
                    }
                }
                button {
                    text-decoration: none !important;
                    border: none;
                    background-color: transparent;
                }
            }
        }
        .arrow-wrapper {
            display: flex;
            justify-content: flex-end;
            @media (max-width: 991px) {
                align-items: center;
                span {
                    margin: auto 0 auto auto;
                }
            }
        }
    }
    .sub-footer {
        background-color: $wilo-dark;
        font-size: 15px;
        padding: 24px 0;
        .container {
            display: flex;
            justify-content: space-between;
            ul, p {
                display: inline-block;
            }
            ul.information {
                list-style-type: none;
                li {
                    display: inline-block;
                    margin-left: 12px;
                    @media (max-width: 768px) {
                        &:first-child {
                            margin-left:0px;
                        }
                    }
                    @media (max-width: 480px) {
                        width: calc(50% - 24px);
                        margin: 12px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 12px;
                        &:first-child {
                            margin-left:12px;
                        }
                    }
                    a, button {
                        color: $wilo-lightgrey-font;
                        font-size: inherit;
                    }
                }
            }
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                .information {
                    margin:24px 0;
                }
            }
            @media (max-width: 480px) {
                .information {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                }
            }
        }

        ul.social-media {
            float:right;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;

            @media (max-width: 768px) {
                li:first-child {
                    margin-left: 0;
                }
            }

            li {
                float: left;
                margin:0 0 0 12px;


                a, button {
                    display:block;
                    color: $wilo-lightgrey-font;
                    font-size: 20px;
                    @include transition(opacity .3s ease-in-out);
                    opacity:1;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        padding-top: 24px;
        > .container {
            margin-bottom: 24px;
        }
    }
}