.associations-teaser {
    .teaser-outer {
        .teaser {
            &:hover {
                .wilo-btn {
                    background-color: $wilo-green;
                }
            }

            .teaser-inner {
                display: flex;
                flex-direction: column;
                img {
                    width: auto;
                    max-width: 100%;
                    max-height:150px;
                    margin: 0 auto;
                    mix-blend-mode: multiply;
                }
                @media (max-width: 575px) {
                    height: 300px;
                }
            }
        }
    }
}