.tns {
    &-item {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
    
        &.transparency,
        &:not(.tns-slide-active) {
            opacity: 0.3;
            transition: opacity 300ms ease-in-out;
        }
    }

    
    &--inner {
        display: flex;

        > * {
            height: unset;
        }
    }

    &--controls {
        display: flex;
        align-items: center;
        column-gap: 2rem;

        > button {
            background: $wilo-green;
            color: #fff;
            width: 4.25rem;
            height: 4.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .9;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
    
            &[disabled] {
                opacity: 0.25;
            }
        }
    }

    &--progress {
        flex: 1 0 auto;
        height: auto;
        background-color: inherit;

        &::-webkit-progress-bar {
            background-color: $wilo-lightgrey-box;
            height: .5rem;
        }
        
        &::-webkit-progress-value {
            background-color: $wilo-green;
            height: .5rem;
        }   
    }
}