.tabs-container {
    .tabs-inner {
        background-color: $wilo-dark;
        position: relative;
        >.container {
            min-height: 70px;
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .hamburger {
                margin-left: auto;
                display: none;
                background-color: transparent;
                .hamburger-box {
                    width: 32px;
                    height: 17px;
                    .hamburger-inner {
                        top: 0;
                        &,
                        &:before,
                        &:after {
                            width: 32px;
                        }
                        &:before {
                            top: 8px;
                        }
                        &:after {
                            top: 16px;
                        }
                    }
                }
                &.is-active {
                    .hamburger-box {
                        .hamburger-inner {
                            &:after {
                                transform: translate3d(0, -16px, 0) rotate(-90deg);
                            }
                        }
                    }
                }
            }
            .active-elemt {
                display: none;
                color: #fff;
                text-transform: uppercase;
            }
            .tabs-wrapper {
                list-style-type: none;
                display: flex;
                color: #fff;
                li {
                    cursor: pointer;
                    padding: 24px;
                    transition: background-color, color 0.2s ease-in-out;
                    text-transform: uppercase;
                    font-size: 15px;
                    &.active, &:hover, &:active, &:focus {
                        background-color: $wilo-lightgrey-box;
                        color: $wilo-dark;
                    }
                }
                &.dropdown {
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background-color: $wilo-lightgrey-box;
                    border-bottom: 2px solid $wilo-dark;
                    color: $wilo-dark;
                    z-index: 10;
                    display: none;
                    li {
                        padding: 12px;
                        &:first-child {
                            padding-top: 24px;
                        }
                        &:last-child {
                            padding-bottom: 24px;
                        }
                    }
                    +.active-elemt {
                        display: block;
                        +.hamburger {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}