.text-block{
    width: 66.6%;
    @media (max-width: 991px) {
        width: 100%;
    }
    >* {
        &:last-child {
            margin-bottom: 0px; 
        }
    }
}