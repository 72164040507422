a {
    &.wilo-btn {
        @include transition(all .3s ease-in-out);
        &:hover {
            background-color: $wilo-green;
        }
    }
}

.download-list, .breadcrumb {
    a {
        @include transition(all .3s ease-in-out);
        opacity: 1;
        &:hover {
            opacity: 0.7;
        }
    }
}

.teaser, .news-teaser {
    a.wilo-btn {
        &:hover {
            opacity: 1;
        }
        &.dark {
            &:hover {
                background-color: $wilo-dark;
            }
        }
    }
}

.overlay-teaser {
    .wilo-btn {
        &.dark, &.green {
            &:hover {
                background-color: $wilo-lightgrey-box;
                color: $wilo-darkgrey;
            }
        }
    }
}

