.text{
    ul {
        list-style-type: none;
        margin-bottom: 24px;
        li {
            display: flex;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0px;
            }
            &::before {
                margin: 9px 20px auto 0;
                content: "";
                width: 5px;
                height: 5px;
                background-color: $wilo-dark;
                display: inline-block;
                border-radius: 50%;
                max-width: 5px;
                max-height: 5px;
                min-height: 5px;
                min-width: 5px;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}

