.ar-nav-wrapper {
    overflow: hidden;
    ul {
        list-style-type: none;
        position: relative;
        height: 100%;
    }
    .sub-menu {
        li.highlight {
            a {
                color: $wilo-green;
            }
        }
    }
    @at-root {
        .ar-nav {
            transform: translateX(0%);
            @include transition(transform .5s ease-in-out);
            >li {
                .ar-nav-item,
                .ar-nav-back {
                    font-size: 15px;
                    padding: 12px 42px 12px 12px;
                    background-color: $wilo-dark;
                    color: #fff;
                    position: relative;
                    margin-bottom: 12px;
                    transform: translateX(0);
                    cursor: pointer;
                    @include transition(all .3s ease-in-out);
                    &:hover {
                        background-color: $wilo-green;
                    }
                    span {
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .ar-nav-back {
                    padding: 12px 12px 12px 42px;
                    span {
                        left: 12px;
                    }
                }
                .sub-menu {
                    width: 100%;
                    height: 100%;
                    display: none;
                    ul {
                        padding: 0 12px;
                        flex-basis: 1;
                        overflow: auto;
                    }
                    li {
                        margin-bottom: 12px;
                        a {
                            @include transition(all .3s ease-in-out);
                            &:hover {
                                color:$wilo-green;
                            }
                        }
                    }
                }
                &.active {
                    .sub-menu {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
            &.active {
                transform: translateX(-100%) translateX(- 12px);
            }
        }
    }
}

.ar-content {
    .container,  .container-fluid{
        padding: 0;
    }
    .page-section {
        width: 100%;
    }
}

@media(min-width: 992px) {
    .ar-nav-wrapper {
        .sub-menu {
            padding: 0 12px;
            position: absolute;
            top: 0;
            left: 100%;
        }
    }
}

@media(max-width: 991px) {
    .ar-nav-wrapper {
        order: 1;
    }
    .ar-content {
        order: 2;
    }
    .ar-nav-back {
        display: none;
    }
    .ar-nav>li {
        .ar-nav-item span {
            transform: translateY(-50%) rotate(90deg);
        }
        &.active {
            .ar-nav-item span {
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }
    .ar-nav {
        &.active {
            transform: none;
        }
    }
}