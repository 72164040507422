// @charset "UTF-8";
// .autocomplete-suggestions {
//   background: #fff;
//   border: 1px solid #999;
//   overflow: auto;
// }
// .autocomplete-suggestions strong {
//   font-weight: bolder;
// }
// .autocomplete-suggestion {
//   font-size: 12px;
//   overflow: hidden;
//   padding: 2px 5px;
//   white-space: nowrap;
// }
// .autocomplete-selected {
//   background: #c3c3c3;
// }

@media screen and (prefers-reduced-motion: reduce) {
  .mwf-button {
    transition: none;
  }
}
// .mwf-button {
//   background: none;
//   border: 0;
//   color: inherit;
//   cursor: pointer;
//   font: inherit;
//   outline: none;
//   padding: 1em 1.5em;
//   transition: 250ms ease;
// }

// .mwf-button--next,
// .mwf-button--submit {
//   background: #4299e1 !important;
//   color: #fff;
//   font-weight: bold;
// }

// .mwf-button--back,
// .mwf-button--cancel {
//   background: #fff;
//   color: #000;
//   font-weight: bold;
//   outline: 1px solid #333;
//   outline-offset: -1px;
// }

// .mwf-button:hover,
// .mwf-button:focus {
//   background: #2d3748;
//   color: #fff;
// }

// .mwf-button:focus {
//   outline: 1px solid #fff;
//   outline-offset: -4px;
// }

// .mwf-button.is-disabled {
//   background: #cbd5e0;
//   color: #000;
//   cursor: not-allowed;
//   outline: none;
// }

// .mwf-button.is-disabled:focus {
//   outline: 1px solid #fff;
// }

// .mwf-calculatedvalue__label {
//   font-size: 1.125em;
//   font-weight: bold;
//   margin-bottom: 8px;
// }
// .mwf-calculatedvalue__value {
//   color: #4a5568;
//   margin: 0;
// }

.mwf-captcha {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.mwf-captcha > * {
  flex: 1 100%;
  margin-bottom: 0.5em;
}
.mwf-captcha__label {
  font-size: 1.125em;
  font-weight: bold;
  order: 1;
}
.mwf-captcha__error {
  color: #f56565;
  font-weight: bold;
  order: 4;
}
.mwf-captcha__hint {
  color: #4a5568;
  order: 3;
}
.mwf-captcha__wrapper {
  order: 2;
}
.mwf-captcha__img {
  border-radius: 3px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
  display: block;
  margin-bottom: 8px;
}
.mwf-captcha__input {
  border: 2px solid #1a202c;
  border-radius: 2px;
  font-size: 1.25em;
  padding: 0.5rem;
  display: block;
}
.mwf-captcha__input:hover {
  outline: 2px solid #999;
  outline-offset: -3px;
}
.mwf-captcha__input:focus {
  outline: 3px solid #999;
  outline-offset: -4px;
}
.has-error .mwf-captcha__input {
  border: 2px solid #f56565;
}

.mwf-checkboxgroup {
  border: 0;
  margin: 0;
  padding: 0;
}
.mwf-checkboxgroup > * {
  margin-bottom: 0.5em;
}
.mwf-checkboxgroup__legend {
  font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 0;
}
.mwf-checkboxgroup__hint {
  color: #4a5568;
}
.mwf-checkboxgroup__error {
  color: #f56565;
  font-weight: bold;
}
.mwf-checkboxgroup__options {
  display: flex;
  flex-direction: column;
  // margin: 0.5rem -0.75rem 1.5rem;
}
.mwf-checkboxgroup__options > * {
  margin: 0.75rem;
}
.mwf-horizontal .mwf-checkboxgroup__options {
  flex-flow: row wrap;
}
.mwf-checkboxgroup__img {
  border-radius: 3px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}

.mwf-checkbox > *:not(:last-child) {
  margin-bottom: 0.5em;
}
.mwf-checkbox__label {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.mwf-checkbox__text {
  align-items: center;
  display: flex;
  position: relative;
}
.mwf-checkbox__text::before {
  background-color: transparent;
  // border: 1px solid #1a202c;
  // border-radius: 2px;
  content: "";
  display: inline-block;
  // height: 1em;
  // margin-right: 0.3em;
  // width: 1em;
}
.mwf-no-toggle .mwf-checkbox__text::before {
  content: unset;
}
// .mwf-checkbox__input:hover + .mwf-checkbox__label .mwf-checkbox__text::before {
//   outline: 1px solid #2d3748;
//   outline-offset: -2px;
// }
// .mwf-checkbox__input:focus + .mwf-checkbox__label .mwf-checkbox__text::before {
//   outline: 2px solid #ed8936;
//   outline-offset: -3px;
// }
// .mwf-checkbox__img {
//   border-radius: 3px;
//   max-height: 100px;
//   max-width: 100px;
// }
// .mwf-checkbox__img-wrapper {
//   font-size: 0;
//   margin-left: 22px;
//   margin-top: 10px;
//   position: relative;
// }
// .mwf-checkbox__input:checked ~ .mwf-checkbox__label .mwf-checkbox__text::before {
//   background-color: #1a202c;
//   box-shadow: inset 0 0 0 3px #fff;
// }
.mwf-image-only .mwf-checkbox__text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.mwf-no-toggle .mwf-checkbox__text {
  color: #fff;
  font-size: large;
  left: 8px;
  position: relative;
  text-shadow: 1px 1px 2px #1a202c;
  top: 5px;
}
.mwf-checkbox__input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.mwf-no-toggle .mwf-checkbox__input ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper {
  margin: 0;
  top: -20px;
  z-index: -1;
}
.mwf-no-toggle .mwf-checkbox__input:checked ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper::after {
  // border: 1px solid #1a202c;
  // border-radius: 2px;
  bottom: 0;
  // box-shadow: #ed8936 0 0 2px 2px inset;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mwf-image-only .mwf-checkbox__input ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper {
  margin: 0;
}
.mwf-image-only .mwf-checkbox__input ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper::before {
  border: 2px solid rgba(128, 128, 128, 0.795);
  border-radius: 2px;
  box-shadow: rgba(128, 128, 128, 0.3) 0 0 1px 1px;
  content: "";
  height: 20px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 20px;
}
// .mwf-image-only .mwf-checkbox__input:checked ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper::before {
  // background-color: #ed8936;
  // border: 2px solid #ed8936;
// }
.mwf-image-only .mwf-checkbox__input:checked ~ .mwf-checkbox__label .mwf-checkbox__img-wrapper::after {
  background: #fff;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
  content: "";
  height: 2px;
  left: 10px;
  position: absolute;
  top: 14px;
  transform: rotate(45deg);
  width: 2px;
}

.mwf-select {
  display: flex;
  flex-flow: column nowrap;
  // flex-direction: column-reverse;
}
.mwf-select > * {
  margin-bottom: 0.5em;
}
.mwf-select__img {
  border-radius: 3px;
  // box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}
.mwf-select__label {
  // font-size: 1.125em;
  // font-weight: bold;
  order: 0;
}
.mwf-select__hint {
  // color: #4a5568;
  order: 3;
}
.mwf-select__error {
  color: #f56565;
  font-weight: bold;
  order: 4;
}
.mwf-select__select {
  // border: 2px solid #1a202c;
  // border-radius: 2px;
  // font-size: 1.25em;
  padding: 0.5rem;
  font-size: 1rem;
  max-width: 100%;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  order: 2;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.mwf-select__select:hover {
  outline: 2px solid #999;
  outline-offset: -3px;
}
.mwf-select__select:focus {
  outline: 3px solid #999;
  outline-offset: -4px;
}
.has-error .mwf-select__select {
  border: 1px solid #f56565;
}
.mwf-select--s .mwf-select__select {
  width: 150px;
}
.mwf-select--m .mwf-select__select {
  width: 300px;
}
.mwf-select--l .mwf-select__select {
  width: 100%;
}
.mwf-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.mwf-fieldset__legend {
  // font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 0;
}
.mwf-fieldset__content {
  border: 1px solid #a0aec0;
  padding: 1rem;
}
.mwf-fieldset--rounded .mwf-fieldset__content {
  border-radius: 50px;
}
.mwf-fieldset--inset .mwf-fieldset__content {
  // box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.15);
}
.mwf-fieldset--background .mwf-fieldset__content {
  background: #e0f2fe;
}

.mwf-file__legend {
  display: flex;
  flex-flow: column;
}
.mwf-file__label {
  font-size: 1.125em;
  font-weight: bold;
  flex: 1 100%;
  margin: 0;
}
.mwf-file__hint {
  color: #4a5568;
  flex: 1 100%;
  margin-top: 0.5em;
}
.mwf-file__error {
  color: #f56565;
  font-weight: bold;
  flex: 1 100%;
  margin-top: 0.5em;
}
.mwf-file__uploaded-heading {
  font-size: 1.2em;
  padding: 0 8px;
  text-align: right;
}
.mwf-file__uploaded-actions {
  margin-bottom: 1em;
}
@media screen and (prefers-reduced-motion: reduce) {
  .mwf-file__uploaded-button {
    transition: none;
  }
}
.mwf-file__uploaded-button {
  background: transparent;
  border: 1px solid #1a202c;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  padding: 0.5em 1em;
  transition: 200ms ease;
}
.mwf-file__uploaded-button:hover, .mwf-file__uploaded-button:focus {
  background: #2d3748;
  border-color: transparent;
  color: #fff;
}
.mwf-file__ul {
  list-style: none;
  padding: 0;
}
.mwf-file__li {
  background: #f1f1f1;
  border-radius: 3px;
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0;
  padding: 16px;
}
.mwf-file__li > * {
  margin: calc(1em / 2) 0;
}
.mwf-file__img {
  border-radius: 3px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
  display: block;
  max-height: 120px;
  max-width: 120px;
  width: 100%;
}
.mwf-file__file-progress {
  background-color: #e0e0e0;
  border-radius: 3px;
  margin: 8px 0;
  width: 100%;
}
.mwf-file__file-progress div {
  background: #ed8936;
  border-radius: 3px;
  color: #fff;
  padding: 6px;
  text-align: right;
}
.mwf-file__file-info {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: calc(1em / 2);
  padding: 8px;
}
.mwf-file__file-info > div {
  display: flex;
  flex-direction: column;
}
.mwf-file__file-info .mwf-file__file-size {
  color: rgba(0, 0, 0, 0.5);
  margin: 4px 0 12px;
}
.mwf-file__file-error {
  color: #f56565;
  font-weight: bold;
}
.mwf-file__file-actions {
  flex: 0;
}
@media screen and (prefers-reduced-motion: reduce) {
  .mwf-file__file-button {
    transition: none;
  }
}
.mwf-file__file-button {
  background: transparent;
  border: 1px solid #1a202c;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  padding: 0.5em 1em;
  transition: 200ms ease;
}
.mwf-file__file-button:hover, .mwf-file__file-button:focus {
  background: #2d3748;
  border-color: transparent;
  color: #fff;
}
.mwf-file__dropzone {
  align-items: center;
  border: 2px dashed #8b9bba;
  display: flex;
  justify-content: center;
  padding: 3em 1em;
  width: 100%;
}
@media screen and (prefers-reduced-motion: reduce) {
  .mwf-file__button {
    transition: none;
  }
}
.mwf-file__button {
  background: #4299e1;
  border-radius: 3px;
  color: #fff;
  display: block;
  padding: 1em 1.5em;
  transition: 300ms ease;
}
.mwf-file__button:hover, .mwf-file__button:focus-within {
  background: #2d3748;
  color: #fff;
  cursor: pointer;
}
.mwf-file__input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.mwf-form {
  box-sizing: border-box;
  overflow: hidden;
  // max-width: 600px;
  margin: auto;
  padding: 5px;
}
.mwf-form *,
.mwf-form *::before,
.mwf-form *::after {
  box-sizing: inherit;
}
.mwf-form__heading {
  font-size: 2em;
  margin-bottom: 8px;
}
.mwf-form__errors {
  color: #f56565;
  font-weight: bold;
}
.mwf-form__actions {
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
}

.mwf-hidden {
  display: none !important;
}

.mwf-textinput {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.mwf-textinput > * {
  flex: 1 100%;
  margin-bottom: 0.5em;
}
.mwf-textinput__label {
  // font-size: 1.125em;
  // font-weight: bold;
  order: 1;
  width: 100%;
}
.mwf-textinput__error {
  color: #f56565;
  font-weight: bold;
  order: 4;
}
.mwf-textinput__hint {
  color: #4a5568;
  order: 3;
}
.mwf-textinput__input {
  // border: 2px solid #1a202c;
  // border-radius: 2px;
  // font-size: 1.25em;
  padding: 0.5rem;
  flex-grow: 0;
  flex-shrink: 1;
  order: 2;
  width: 100%;
}
.mwf-textinput__input:hover {
  outline: 2px solid #999;
  outline-offset: -3px;
}
.mwf-textinput__input:focus {
  outline: 3px solid #999;
  outline-offset: -4px;
}
.has-error .mwf-textinput__input {
  border: 2px solid #f56565;
}
.mwf-layout--stacked .mwf-textinput__input {
  flex: 1;
}
.mwf-layout--default .mwf-textinput__input {
  min-width: 50px;
}
.mwf-layout--default .mwf-textinput--xs .mwf-textinput__input {
  flex-basis: 50px;
}
.mwf-layout--default .mwf-textinput--s .mwf-textinput__input {
  flex-basis: 100px;
}
.mwf-layout--default .mwf-textinput--m .mwf-textinput__input {
  flex-basis: 150px;
}
.mwf-layout--default .mwf-textinput--l .mwf-textinput__input {
  flex-basis: 200px;
}
.mwf-layout--default .mwf-textinput--xl .mwf-textinput__input {
  flex-basis: 250px;
}
.mwf-layout--default .mwf-textinput--xxl .mwf-textinput__input {
  flex-basis: 300px;
}
.mwf-layout--stacked .mwf-textinput--xs {
  flex: 1 0 50px;
}
.mwf-layout--stacked .mwf-textinput--xs .mwf-textinput__input {
  min-width: 50px;
}
.mwf-layout--stacked .mwf-textinput--s {
  flex: 2 0 100px;
}
.mwf-layout--stacked .mwf-textinput--s .mwf-textinput__input {
  min-width: 100px;
}
.mwf-layout--stacked .mwf-textinput--m {
  flex: 3 0 150px;
}
.mwf-layout--stacked .mwf-textinput--m .mwf-textinput__input {
  min-width: 150px;
}
.mwf-layout--stacked .mwf-textinput--l {
  flex: 4 0 200px;
}
.mwf-layout--stacked .mwf-textinput--l .mwf-textinput__input {
  min-width: 200px;
}
.mwf-layout--stacked .mwf-textinput--xl {
  flex: 5 0 250px;
}
.mwf-layout--stacked .mwf-textinput--xl .mwf-textinput__input {
  min-width: 250px;
}
.mwf-layout--stacked .mwf-textinput--xxl {
  flex: 6 0 300px;
}
.mwf-layout--stacked .mwf-textinput--xxl .mwf-textinput__input {
  min-width: 300px;
}

.mwf-break {
  flex-basis: 100%;
  height: 0;
  margin: 0;
}

// .mwf-form-steps {
//   margin-bottom: 1.5em;
// }
// .mwf-form-steps__heading {
//   margin-bottom: 32px;
// }
// .mwf-form-steps__list {
//   counter-reset: form-step;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
//   position: relative;
// }
// @media only screen and (min-width: 600px) {
//   .mwf-form-steps__list {
//     align-items: center;
//     flex-direction: row;
//   }
// }
// .mwf-form-steps__list::before {
//   background-color: #e7e7e7;
//   content: "";
//   height: 3px;
//   position: absolute;
//   width: 100%;
//   z-index: -1;
// }
// @media only screen and (max-width: 600px) {
//   .mwf-form-steps__list::before {
//     display: none;
//   }
// }
// .mwf-form-steps__item {
//   align-items: center;
//   background-color: #2d3748;
//   border-radius: 2px;
//   color: #fff;
//   display: flex;
//   max-width: 400px;
//   padding: 14px 20px;
// }
// @media only screen and (max-width: 600px) {
//   .mwf-form-steps__item {
//     margin-bottom: 8px;
//   }
// }
// .mwf-form-steps__item::before {
//   align-items: center;
//   content: counter(form-step);
//   counter-increment: form-step;
//   display: flex;
//   height: 20px;
//   justify-content: center;
//   margin-right: 8px;
// }
// .mwf-form-steps__item--completed {
//   background-color: #4299e1;
//   cursor: pointer;
//   list-style-type: none;
// }
// .mwf-form-steps__item--completed span {
//   clip: rect(1px, 1px, 1px, 1px);
//   height: 1px;
//   overflow: hidden;
//   position: absolute !important;
//   white-space: nowrap;
//   width: 1px;
// }
// .mwf-form-steps__item--completed::before {
//   content: "✓";
// }
// .mwf-form-steps__item--current {
//   background-color: #4299e1;
// }
// .mwf-form-steps__item--current span {
//   clip: rect(1px, 1px, 1px, 1px);
//   height: 1px;
//   overflow: hidden;
//   position: absolute !important;
//   white-space: nowrap;
//   width: 1px;
// }

.mwf-radiogroup {
  border: 0;
  margin: 0;
  padding: 0;
}
.mwf-radiogroup > * {
  margin-bottom: 0.5em;
}
.mwf-radiogroup__legend {
  font-size: 1.125em;
  // font-weight: bold;
  margin-bottom: 8px;
  padding: 0;
}
.mwf-radiogroup__hint {
  color: #4a5568;
}
.mwf-radiogroup__error {
  color: #f56565;
  font-weight: bold;
}
.mwf-radiogroup__options {
  display: flex;
  flex-direction: column;
  margin: 0.5rem -0.75rem 1.5rem;
}
.mwf-radiogroup__options > * {
  margin: 0.75rem;
}
.mwf-horizontal .mwf-radiogroup__options {
  flex-flow: row wrap;
}
.mwf-radiogroup__img {
  border-radius: 3px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}

.mwf-radio > *:not(:last-child) {
  margin-bottom: 0.5em;
}
.mwf-radio__label {
  display: inline-flex;
  flex-flow: row;
  flex-direction: column;
  position: relative;
}
.mwf-radio__text {
  line-height: 1;
  position: relative;
}
// .mwf-radio__text::before {
//   background-color: transparent;
//   border: 1px solid #1a202c;
//   border-radius: 50%;
//   content: "";
//   display: inline-block;
//   height: 1em;
//   margin-right: 0.3em;
//   position: relative;
//   top: 2px;
//   width: 1em;
// }
.mwf-no-toggle .mwf-radio__text::before {
  content: unset;
}
.mwf-radio__input:hover + .mwf-radio__label .mwf-radio__text::before {
  box-shadow: inset 0 0 2px 1px #2d3748;
}
.mwf-radio__input:focus + .mwf-radio__label .mwf-radio__text::before {
  box-shadow: inset 0 0 3px 2px #ed8936;
}
.mwf-radio__img {
  border-radius: 3px;
  max-height: 100px;
  max-width: 100px;
}
.mwf-radio__img-wrapper {
  font-size: 0;
  margin-left: 22px;
  margin-top: 10px;
  position: relative;
}
.mwf-radio__input:checked ~ .mwf-radio__label .mwf-radio__text::before {
  background-color: #1a202c;
  box-shadow: inset 0 0 0 3px #fff;
}
.mwf-image-only .mwf-radio__text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.mwf-no-toggle .mwf-radio__text {
  color: #fff;
  font-size: large;
  left: 8px;
  position: relative;
  text-shadow: 1px 1px 2px #1a202c;
  top: 5px;
}
.mwf-radio__input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.mwf-no-toggle .mwf-radio__input ~ .mwf-radio__label .mwf-radio__img-wrapper {
  margin: 0;
  top: -20px;
  z-index: -1;
}
.mwf-no-toggle .mwf-radio__input:checked ~ .mwf-radio__label .mwf-radio__img-wrapper::after {
  border: 1px solid #1a202c;
  border-radius: 3px;
  bottom: 0;
  box-shadow: #ed8936 0 0 2px 2px inset;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mwf-image-only .mwf-radio__input ~ .mwf-radio__label .mwf-radio__img-wrapper {
  margin: 0;
}
.mwf-image-only .mwf-radio__input ~ .mwf-radio__label .mwf-radio__img-wrapper::before {
  border: 2px solid rgba(128, 128, 128, 0.795);
  border-radius: 50%;
  box-shadow: rgba(128, 128, 128, 0.3) 0 0 1px 1px;
  content: "";
  height: 20px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 20px;
}
.mwf-image-only .mwf-radio__input:checked ~ .mwf-radio__label .mwf-radio__img-wrapper::before {
  background-color: #ed8936;
  border: 2px solid #ed8936;
}

.mwf-summary__label {
  font-size: 1.125em;
  font-weight: bold;
}
.mwf-summary__list {
  list-style: none;
  padding-left: 0;
}
.mwf-summary-item__label {
  font-weight: bold;
}
.mwf-summary-item__label::after {
  content: ":";
}

.mwf-textarea {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
}
.mwf-textarea > * {
  flex: 1 100%;
  margin-bottom: 0.5em;
}
.mwf-textarea__label {
//   font-size: 1.125em;
  // font-weight: bold;
  order: 1;
}
.mwf-textarea__error {
  color: #f56565;
  font-weight: bold;
  order: 4;
}
.mwf-textarea__hint {
  color: #4a5568;
  order: 3;
}
.mwf-textarea__textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 1.25em;
  padding: 0.5rem;
  // flex: 0 50%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-height: 120px;
  min-width: 100px;
  order: 2;
}
.mwf-textarea__textarea:hover {
  outline: 2px solid #999;
  outline-offset: -3px;
}
.mwf-textarea__textarea:focus {
  outline: 3px solid #999;
  outline-offset: -4px;
}
.has-error .mwf-textarea__textarea {
  border: 2px solid #f56565;
}
.mwf-textarea__textarea[cols] {
  flex-basis: auto;
}
.mwf-textarea--s .mwf-textarea__textarea {
  flex-basis: 150px;
}
.mwf-textarea--m .mwf-textarea__textarea {
  flex-basis: 300px;
}
.mwf-textarea--l .mwf-textarea__textarea {
  flex-basis: 600px;
}

.mwf-layout {
  margin: 0 calc(2rem / 2 * -1);
}
.mwf-layout > * {
  margin: calc(2rem / 2);
}
.mwf-layout--stacked {
  display: flex;
  flex-flow: row wrap;
}
.mwf-layout--stacked > * {
  flex: 1 1 100%;
}