.teaser-wrapper {
    .teaser-outer {
        .teaser {
            .teaser-inner{
                &.teaser-sponsoring {
                    padding: 0;

                    img {
                        display: block;
                    }
                    > *:first-child {
                        margin-bottom:0;
                    }
                }
            }
        }
    }
}