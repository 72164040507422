.modal {
    z-index: $modal-z-index;
    padding-top: 5vh;
    position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    
    &--background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.9;
    }

    &--close {
        opacity: 1;
        position: absolute;
        top: 2vh;
        right: 5vh;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        transition: .3s;

        &:hover {
            color: gray;
            cursor: pointer;
        }

        &::before {
            content: "x";
        }
    }

    &--content {
        z-index: $modal-content-z-index;
        opacity: 1;
    }
}