ol.breadcrumb {
    list-style-type: none;
    margin: 0 0 36px 0;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    display:flex;

    li {
        float: left;

        a {
            display: block;
            text-align: center;
            padding: 0px 12px 0 0;
            text-decoration: none;
            p, h1 {
                font-family: $NotoSans;
                font-size: 12px;
                display:inline;
                text-transform: none;
                line-height: normal;
                font-weight:400;
                &:after {
                    content:">";
                    padding-left: 12px;
                }
            }
        }

        &:last-child {
            a {
                color: $wilo-green;

                p, h1 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    flex: 1;
                    &:after {
                        content: "";
                        display:none;
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        max-width: calc(100% - 12px);
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        display:none;
    }
}