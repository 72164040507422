/** TODO: compare to actual implementation **/
.teaser__download-teaser {
  position: relative;
  padding-top: 90%;
  background-color: #e9eaea;

  /*
        &.lightgrey {
          background-color: #e9eaea;
        }
      
        &.green {
          background-color: #009c82;
        }
      
        &.dark {
          background-color: #363640;
        }*/

  .teaser-inner {
    display: block;
    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    h3 {
      font-size: 1.5rem;
      font-family: $NotoBold;
      text-transform: uppercase;
      &.color-dark {
        color: #363640 !important;
      }
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.25rem;
      &.color-dark {
        color: #363640 !important;
      }
    }

    .download-list li a,
    ::before {
      color: #009c82 !important;
    }
  }
}
