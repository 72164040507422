.animated-text {
    $self: &;
    margin-bottom: 120px;
    
    &__content {        
        position: relative;
        transition: translate 600ms ease-out, opacity 600ms ease-out;
        opacity: 0;
        translate: -50% 0;
    }

    &.visible {
        #{$self}__content {
            opacity: 1;
            translate: 0 0;
        }
    }

    &__text {
        position: relative;
        padding: 41px 0 0 45px;

        p {          
            font-size: 1rem;
            line-height: 1.625rem;
            color: var(--text-color, $wilo-dark);
        }
    }

    &__icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        aspect-ratio: 1 / 1;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__cta {
        display: flex;
        padding-top: 40px;
        justify-content: center;
        
        .wilo-btn--outline {
            color: var(--text-color, $wilo-dark);
            border-color: var(--text-color, $wilo-dark);
        }
    }

    @media (min-width: 992px) {
        margin-bottom: 240px;

        &__cta {
            display: flex;
            justify-content: center;
            font-size: 1.125rem;
        }
    }
}