.animated-text-image-section {
    $self: &;
    margin-bottom: 120px;
    overflow: hidden;

    @media (min-width: 767px) {
        margin-bottom: 240px;
    }

    &__text,
    &__image {
        opacity: 0;
        transition: 
            translate  600ms ease-in-out, 
            opacity 600ms ease-in-out;
    }

    &--left {
        #{$self}__content {
            flex-direction: column;
            @media (min-width: 767px) {
                flex-direction: row;
            }
        }
        #{$self}__image {
            translate: -50% 0;
        }
        #{$self}__text {
            translate: 50% 0;
        }
    }

    &--right {
        #{$self}__content {
            flex-direction: column;
            @media (min-width: 767px) {
                flex-direction: row-reverse;
            }
        }

        #{$self}__image {
            translate: 50% 0;
        }
        #{$self}__text {
            translate: -50% 0;
        }
    }

    &.visible {
        .animated-text-image-section__image, 
        .animated-text-image-section__text {
            translate: 0;
            opacity: 1;
        }
    }

    &__content {
        align-items: center;
        gap: 48px;

        @media (min-width: 767px) {
            gap: 0;
            justify-content: space-between;
        }
    }

    &__supheadline {
        text-transform: uppercase;
        color: $wilo-green;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 8px;
    }

    &__headline {
        font-size: 2.25rem;
        line-height: 2.8125rem;
        margin-bottom: 1.5rem;
        color: var(--text-color, $wilo-dark);
    }

    &__text {
        font-size: 1.125rem;
        line-height: 1.4;
        color: var(--text-color, $wilo-dark);
    }

    &__cta {
        margin-top: 2rem;
        font-size: 1rem;

        .wilo-btn--outline {
            color: var(--text-color, $wilo-dark);
            border-color: var(--text-color, $wilo-dark);
        }
    }


}