.countrySelection {
  color: #363640;
}

.cs__title {}

.cs__continentList,
.cs__countryList {
  list-style-type: none;
}

.cs__continentList {}

.cs__continentItem {}

.cs__continentTitle {}

.cs__countryItem {
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs__countryImage {
  width: 32px;
}

.cs__countryTitle {
  line-height: 1;
}

@media (max-width: 480px) {
  .cs__country {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .cs__countryItem {
    flex-direction: row;
    height: 32px;
  }
}