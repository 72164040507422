blockquote {
  padding: 10px;
  position: relative;

  &.background-blue {
    background-color: #033e63;
    .quote,
    .author {
      color: white;
    }
  }
  &.background-green {
    background-color: #169c82;
    .quote,
    .author {
      color: white;
    }
  }
  &.background-grey {
    background-color: #505050;
    .quote,
    .author {
      color: white;
    }
  }
  &.bg-white {
    background-color: inherit;
    .quote,
    .author {
      color: $wilo-dark;
    }
  }

  .row {
    border-left: 5px solid #eee;
    margin-left: 0;
    align-items: flex-start;
    padding: 10px 20px;
  }
  .quote {
    font-weight: 700;
    text-transform: none;
    &.size-md p {
      font-size: 36px;
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
    &.size-sm p {
      font-size: 20px;
      @media (max-width: 575px) {
        font-size: 15px;
      }
    }
  }

  .author {
    font-style: italic;
  }

  .caption {
    font-size: 12px;
    margin-top: 12px;
  }
}
