.table-section {
    table {
        tr {
            &:nth-child(2n) {
                background-color: $wilo-lightgrey-box;
            }
        }
    }
}
.table-section,
.annual-report-table {
    overflow-x: auto;
    table {
        border: none;
        width: 100%;
        border-collapse: collapse;

        &.fc-bld {
            tr {
                td:first-child {
                    font-family: $NotoBold;
                }
            }
        }

        &.fc-clr {
            tr {
                td:first-child {
                    color: $wilo-green;
                }
            }
        }

        tbody {
            td {
                &.table-row-child{
                    padding-left: 48px;
                }
                &.table-row-child-level-2 {
                    padding-left: 72px;
                }
            }
            tr {
                &:first-child {
                    border-top: 2px solid $wilo-dark;
                }
                &:last-child {
                    border-bottom: 2px solid $wilo-dark;
                }
            }
        }
        tr {
            td,
            th {
                text-align: left;
                padding: 24px;
                &.text-right {
                    text-align:right;
                }
                &.text-center {
                    text-align:center;
                }
                &.text-green {
                    color: $wilo-green;
                }
            }
        }
    }
}

.annual-report-table {
    td {
        position: relative;
        --border-color: #{$wilo-lightgrey-box};
    }
    td.td-grey {
        background-color: $wilo-lightgrey-box;
        --border-color: white;
    }
    td.separator-bottom {
        &::before {
            display: block;
            content: "";
            background-color: var(--border-color);
            position: absolute;
            left: 12px;
            bottom: 0;
            height: 2px;
            right: 0;
        }

        & ~ .separator-bottom {
            &:before {
                left: 0;
            }
        }
    }
    td.separator-bottom:last-child {
        &:before {
            right: 12px;
        }
    }
}