.career {
    width: 100%;
    position: relative;
    border: 0;
    z-index: 2;
}

.career-overlay {
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 1;
}

.career-overlay--active {
    display: block;
}

.career-wrapper {
    padding: 0;
}