.icon-facebook:before {
    content: '\21';
}

/* '!' */
.icon-twitter:before {
    content: '\22';
}

/* '&quot;' */
.icon-youtube:before {
    content: '\23';
}

/* '#' */
.icon-linkedin:before {
    content: '\24';
}

/* '$' */
.icon-all:before {
    content: '\e885';
}

/* '' */
.icon-events:before {
    content: '\e8d0';
}

/* '' */
.icon-fax:before {
    content: '\e8d1';
}

/* '' */
.icon-file:before {
    content: '\e8d2';
}

/* '' */
.icon-images:before {
    content: '\e8d3';
}

/* '' */
.icon-mail:before {
    content: '\e8d4';
}

/* '' */
.icon-news:before {
    content: '\e8d6';
}

/* '' */
.icon-products:before {
    content: '\e8d7';
}

/* '' */
.icon-social-media:before {
    content: '\e8d8';
}

/* '' */
.icon-videos:before {
    content: '\e8d9';
}


/* '' */
$font-sm: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 24px;

/* Font Sizes */
.fs--sm {
    font-size: $font-sm;
}

.fs--md {
    font-size: $font-md;
}

.fs--lg {
    font-size: $font-lg;
}

.fs--xl {
    font-size: $font-xl;
}

/* Font Weights */
@mixin fw--r {
    font-family: $NotoSans;
}

.fw--r{
    @include fw--r;
}

@mixin fw--b {
    font-family: $NotoBold;
}

.fw--b{
    @include fw--b;
}

/* Headlines */
.hl--sm {
    @extend .fs--sm , .fw--b;
}

.hl--md {
    @extend .fs--md , .fw--b;
}

.hl--lg {
    @extend .fs--lg , .fw--b;
}

.hl--xl {
    @extend .fs--xl , .fw--b;
}

.cs-hl-h1 {
    font-size: 28px;
}
.cs-hl-h2 {
    font-size: 22px;
}

/* Upper-/Lowercase*/

.tt {
    &--unset {
        text-transform: unset !important;
    }

    &--uppercase {
        text-transform: uppercase !important;
    }

    &--lowercase {
        text-transform: lowercase !important;
    }    
}