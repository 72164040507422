* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: $NotoSans;
    line-height: 0;
    &:focus {
        outline: none !important;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
li,
table,
td,
tr,
th,
label {
    line-height: 1.5;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    opacity: 1;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
    font-size: 15px;
    font-family: $NotoSans;
    > a {
        color: $wilo-green;
        border-bottom: solid 1px transparent;
        &:hover {
            border-bottom: solid 1px $wilo-green;
        }
    }
}

strong,
b {
    font-family: 'NotoBold', Sans-Serif;
}

img {
    width: 100%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

button {
    &:focus,
    &:hover,
    &:active {
        outline: 0;
    }
}

.container-fluid {
    max-width: 1440px;
}

.page-section {
    margin-bottom: 48px;
}

p.headline {
    line-height: 1.25;
    font-family: 'NotoBold', Sans-Serif;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4 {
    line-height: 1.25;
    font-family: 'NotoBold', Sans-Serif;
    text-transform: uppercase;
    color: $wilo-dark;
}

h1,
h2,
p.headline {
    font-size: 36px;
    font-family: 'NotoBold', Sans-Serif;
    @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 28px;
    }
    @media (max-width: 575px) {
        font-size: 24px;
    }
}

.dark {
    background-color: $wilo-dark;
}

.darkgrey {
    background-color: $wilo-darkgrey;
}

.green {
    background-color: $wilo-green;
}

.lightgrey-box {
    background-color: $wilo-lightgrey-box
}

.color-dark {
    color: $wilo-dark;
}

.color-green {
    color: $wilo-green !important;
}

.wilo-logo {
    color: #fff;
    font-size: 46px;
}

.hidden, .display-none {
    display: none !important;
}

.overflow-hidden {
    overflow: hidden;
}

.flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.relative {
    display: relative !important;
}

.position-relative {
    position: relative !important;
}

.hidden-md {
    @media (max-width: 991px) {
        display: none;
    }
}

.template {
    display: none !important;
}

.margin-right-auto {
    margin-right: auto !important;
}

.margin-left-auto {
    margin-left: auto !important;
}

// Temporary
.overview-section {
    a {
        margin: 12px;
    }
}


body {
    &.theme--dark {
        background-color: $wilo-dark;
    }

    &.theme--blue {
        background-color: $wilo-blue;
    }

    &.theme--green {
        background-color: $wilo-green;
    }

    &.theme--grey {
        background-color: $wilo-grey;
    }

    &.theme--gradient {
        background-image: -o-linear-gradient(top, var(--tw-gradient-stops));
        background-image: -webkit-gradient(linear, left top, left bottom, from(var(--tw-gradient-stops)));
        background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
        --tw-gradient-from: #009C82;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(22, 156, 130, 0));
        --tw-gradient-to: #022E4A;
    }

    &[class*="theme--"] {
        -webkit-filter: saturate(0.9) contrast(1.2);
                filter: saturate(0.9) contrast(1.2);

        .content {
            color: #fff;

            .lightgrey-box,
            .tile {
                color: $wilo-dark;
            }
        }

        .color-dark,
        .color-dark *:not(.teaser.dark *),
        .teaser.dark .overlay *:not(.download-teaser *) {
            color: $wilo-dark;
        }

        .download-teaser .color-dark,
        .download-teaser .download-list a {
            color: #fff !important;
        }
    }
}