.share-container {
    position: fixed;
    z-index: 1000;
    bottom: calc(1.5rem + 3.75rem);
    right: 1.5rem;
    width: 100%;
    color: #fff;
    user-select: none;
    display: flex;
    flex-direction: row-reverse;

    .shariff-wrapper {
        display: none;
        position: absolute;
        flex-direction: row-reverse;
        align-items: center;
        background-color: rgba(54, 54, 64, 0.9);
        border-radius: 5rem;
        height: 3.75rem;
        $transition-timing-fn: cubic-bezier(0.65, 0.05, 0.36, 1);
        max-width: 3.75rem;
        width: auto;
        transition: max-width, width;
        transition-duration: 0.5s;
        transition-timing-function: $transition-timing-fn;

        &.hover {
            max-width: unset;

            .shariff {
                padding: 0 1rem;
                width: 100%;
                opacity: 1;
                visibility: visible;
                color: #fff;
            }
    
            div:first-child {
                i:nth-child(1) {
                    display: none;
                }
                
                i:nth-child(2) {
                    display: block;
                }
              }
        }

        .shariff {
            overflow: hidden;
            display: flex;
            justify-content: center;
            flex-direction: row;
            white-space: nowrap;
            height: 40px;
            padding: 0;
            width: 0;
            opacity: 0;
            transition: padding, width, opacity, visibility;
            transition-duration: 0.5s;
            transition-timing-function: $transition-timing-fn;
            visibility: hidden;
            border-right: solid 1px $wilo-green;

            ul {
                font-size: 1.875rem;
                display: block;
                width: max-content;

                li {
                    display: inline-block;
                    text-align: center;
                    width: 3rem;

                    span {
                        color: #fff;
                        line-height: 1.875rem;
                    }
                }
            }
        }

        div:first-child {
            display: flex;
            align-items: center;

            i {
                font-size: 1.875rem;
                font-style: normal;
                line-height: 3.75rem;
                width: 3.75rem;
                cursor: pointer;
                text-align: center;

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}