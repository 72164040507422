.annual-report {
    .dark-bg-section,
    .light-bg-section {
        padding-top: 60px;

        >*:last-child {
            margin-bottom: 60px;
        }

        @media (min-width: 992px) {
            padding-top: 120px;

            >*:last-child {
                margin-bottom: 120px;
            }
        }
    }

    .dark-bg-section {
        background: #040b0d; 
        padding-bottom: 1px; 
        --text-color: white;
    }

    .annual-report-stage {
        background: #040b0d;
    }

    
}