.video-section {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 767px) {
            img {
                padding: 0 0 12px 0;
            }
        }
    }
}