.text-info-slider {
    margin: 0 auto;
    padding: 1rem 0;
    width: 85%;
    box-sizing: border-box;

    .tns {
        &--footer {
            width: 84%;
        }

        &--controls {
            column-gap: 1rem;

            >button {
                width: 3rem;
                height: 3rem;
            }
        }

        &--progress::-webkit-progress-bar {
            background-color: $wilo-grey;
        }

        &--header {
            color: $wilo-dark;
            margin-bottom: 1rem;
        }
    }
}

.text-info-tile .tile {
    &--inner {
        padding: 0.5rem;
        box-shadow: none;
    }

    &--image > img {
        width: 100%;
        aspect-ratio: 4/3;
    }

    &--content {
        margin-bottom: 5rem;
        text-align: center;
    }

    &--title {
        font-weight: 700;
        font-size: 1.5rem;
        margin: 16px auto;
    }
}