
input[type=radio]~label:before {
    background-image: url('../../media/forms/radio/radio_default.png');
}

input[type=radio]:checked~label:before {
    background-image: url('../../media/forms/radio/radio_selected.png');
}

input[type=radio]:disabled~label:before {
    background-image: url('../../media/forms/radio/radio_disabled.png');
    cursor: default;
}

input[type=radio]:checked:disabled~label:before {
    background-image: url('../../media/forms/radio/radio_selected_disabled.png');
    cursor: default;
}
