.animated-quote {
    $self: &;
    margin-bottom: 120px;

    &--image {
        #{$self}__author {
            justify-content: flex-start;
        }
    }
    
    &__content {        
        position: relative;
        transition: translate 600ms ease-out, opacity 600ms ease-out;
        opacity: 0;
        translate: -50% 0;
    }

    &.visible {
        #{$self}__content {
            opacity: 1;
            translate: 0 0;
        }
    }

    &__text {
        #{$self}__quote {
            display: flex;
            flex-direction: row;
            gap: 32px;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            img {
                width: 354px;
            }
        }
        blockquote {
            border: none;
            padding: 0;
            p {
                font-size: 1.5rem;
                line-height: 1.4;
                font-weight: 400;
                color: var(--text-color, $wilo-dark);              
                &::before {
                    content: open-quote;
                }
               
                &::after {
                    content: close-quote;
                }
            }
        }
    }
    &__author {
        display: flex;
        justify-content: flex-end;
        font-size: 0.75rem;
        line-height: 1.5;
        color: var(--text-color, $wilo-dark);
        padding-top: 12px;
    }

    &__cta {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-top: 40px;
        gap: 16px;
        
        .wilo-btn--outline {
            color: var(--text-color, $wilo-dark);
            border-color: var(--text-color, $wilo-dark);
            margin: 0;
        }

        @media (max-width: 575px) {
            flex-direction: column;
            gap: 28px;
            align-items: flex-start;
        }

    }

    @media (min-width: 992px) {
        margin-bottom: 240px;

        &--image {
            #{$self}__text {
                blockquote {
                    p {
                        font-size: 2rem;
                    }
                }
            }
        }

        &__text {
            position: relative;

            blockquote {
                p {
                    font-size: 2.25rem;
                }
            }
        }

        &__cta {
            font-size: 1.125rem;
            justify-content: center;
        }
    }
}