.full-size-teaser {
    &:first-child {
        display: flex !important;
    }
    width: 100%;
    display: none !important;
    flex-direction: column;
    position: relative;
    * {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .wilo-btn {
        margin-top: 12px;
    }
    .image-wrapper, img {
        margin-bottom: 48px;
        &:only-child {
            margin-bottom: 0;
        }
    }
    .container {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        .overlay-teaser {
            display: block;
            max-width: 400px;
            background-color: $wilo-green;
            padding: 48px 24px;
            &.right {
                margin-left: auto;
            }
            &.dark {
                color: #fff;
                background-color: $wilo-dark;
                h2,
                h1 {
                    color: $wilo-green;
                }
            }
            h2,
            h1,
            .h2,
            .h1 {
                color: #fff;
                text-transform: uppercase;
            }
            .h1 {
                @extend h1;
            }
            p:last-of-type {
                min-height: 45px;
                @media (max-width: 575px) {
                    min-height: 65px;
                }
            }
        }
    }

    @media (max-width: 1440px) {
        padding: 0;
    }
    @media (max-width: 991px) {
        .container {
            .overlay-teaser {
                max-width: none;
            }
        }
        .image-wrapper, img {
            margin-bottom: 144px;
        }
    }
    @media (max-width: 767px) {
        .image-wrapper, img {
            margin-bottom: 0;
        }
        .container {
            position: static;
            transform: none;
            max-width: none;
            padding: 0;
        }
    }
    @media (max-width: 575px) {
        .container .overlay-teaser {
            padding: 24px;
        }
    }
}

.full-size-teasers-wrapper {
    .full-size-teasers {
        display: flex;
        .full-size-teaser {
            .image-wrapper {
                height: 708px;
                overflow: hidden;
            }
            &.dark {
                @media (min-width: 768px) {
                    background-color: transparent;
                }
            }
            @media (max-width: 1440px) {
                .image-wrapper {
                    height: auto;
                    max-height: 708px;
                }
            }
            @media (max-width: 767px) {
                background-color: $wilo-green;
                &.dark {
                    background-color: $wilo-dark;
                }
            }
        }
    }
    .tns-outer {
        position: relative;
        margin-bottom: 96px;
        .tns-nav {
            position: absolute;
            bottom: -36px;
            z-index: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                width: 50px;
                height: 5px;
                margin: 3px;
                border: none;
                background-color: $wilo-lightgrey-box;
                &.tns-nav-active {
                    background-color: $wilo-dark;
                }
            }
        }
        .tns-inner {
            .full-size-teaser {
                display: flex !important;
            }
        }
    }
    @media (max-width: 1440px) {
        padding: 0;
    }
}