.location-teaser-wrapper {
  background: #E9EAEA;
  padding: 15px;
}

#offcanvas {
  display: none;
  >* {
    display: none;
    &.active {
      display: block;
    }
  }
}

.alert-off-canvas {
  padding: 25px 0;
  background-color: #4a4953;
  color: #fff;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: end;
  }
  @media (max-width: 480px) {
    padding: 15px 0;

    .container {
      justify-content: left;
    }
  }
  .wilo-icon {
    font-size: 20px;
    &:before {
      color: #fff;
      margin-right: 12px;
    }
  }

  .locationRetargeting__close {
    &:before {
      cursor: pointer;
    }
  }
  
  .off-canvas__close {
    cursor: pointer;
      position: absolute;
      right: 15px;
      font-size: 12px;
      top: 0;
  }  
}

.off-canvas-alert-content {
  width: 100% !important;
}
h1.off-canvas-headline {
  margin-bottom: 10px;
}

.off-canvas {
  z-index: 1002;
  position: relative;
}

h1#location-rtt-headline {
  margin-bottom: 0;
  font-size: 28px;
}

p#location-rtt-text {
  margin-bottom: 24px;
}

a#location-rtt-btn-country-site,
a#location-rtt-btn-overview {
  margin-right: 24px;
}

/**
    Location Based Retargeting
*/


.locationRetargeting {
  background-color: #4a4953;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;

      @media (min-width: 768px) {
          flex-wrap: nowrap;
      }
  }
  
  &__flag {
      height: 32px;
      width: 32px;
  }

  &__overview,
  &__link {
      background-color: #363640;
      color: #fff;

      &:hover {
          color: #fff;
      }
  }
  
  &__overview {
      padding: .5rem 1rem .5rem .5rem;
      display: flex;
      align-items: center;
      margin-left: auto;
      min-width: 200px;
      width: 100%;
      margin-bottom: .75rem;
      
      @media (min-width: 480px) {
          width:  auto;
          margin-bottom: 0;
          margin-right: .5rem;
          flex-grow: 2;
      }

      @media (min-width: 768px) {
          flex-grow: 0;
      }
      
      @media (min-width: 992px) {
          min-width: 240px;
      }
  }

  &__link {
      padding: 0 1rem;
      white-space: nowrap;
      width: 100%;
      min-height: 48px;
      line-height: 48px;
      
      @media (min-width: 480px) {
          width:  auto;
          height: 48px;
          flex-grow: 1;
      }

      @media (min-width: 768px) {
          flex-grow: unset;
      }

      &::before {
          font-family: $WiloIcons;
          content: "\e903";
          margin: 0 5px 0 0;
          font-size: 9px;
      }
  }

  &__country {
      margin: 0 0 0 .5rem;
      line-height: 1;
  }

  &__text {
      margin: 0 0 .75rem 0;
      width: 100%;
      
      @media (min-width: 768px) {
          margin: 0 3rem .75rem 0;
          flex-grow: 1;
      }
      
      @media (min-width: 992px) {
          margin: 0 3rem 0 0;
      }

      @media (min-width: 1200px) {
          margin: 0 10rem 0 0;
      }
  }

  &__close {
      font-size: 16px !important;
      margin: 16px auto auto 2rem;

      &::before {
          margin-right: 0 !important;
      }
      
      @media (min-width: 992px) {
          margin: auto auto auto 2rem;
      }
  }

}